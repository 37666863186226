import React, { useState } from "react";
import Button from "@mui/material/Button";
import axiosInstance from "../../axiosInstance";
import axios from "axios";

const PasswordModal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const validPassword = "yourpassword"; // Set your valid password here

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response = await axios.post(
      `${
        process.env.API_ENDPOINT === ""
          ? "http://localhost:3000"
          : process.env.API_ENDPOINT
      }/${process.env.RP_INTERNAL_PATH}`,
      {
        password: password,
      }
    );
    // console.log(response.data.login);
    //   setSitesData(response.data.data);

    if (response.data.login) {
      setIsOpen(false);
      props.onSuccess();
    } else {
      setError("Invalid password. Please try again.");
    }
  };

  if (!isOpen) {
    return null; // Modal is closed
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-100 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
        {/* <h2 className="text-xl font-semibold mb-4">Enter Password</h2> */}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          
          {/* <Button
            size="small"
            type="submit"
            variant="contained"
            sx={{ backgroundColor: "#243060", textTransform: "none" }}
          >
            Submit
          </Button> */}
{error && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="submit"
            className="bg-catelina-blue hover:bg-catelina-blue text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-catelina-blue focus:ring-opacity-50"
          >
            Submit
          </button>
          
        </form>
      </div>
    </div>
  );
};

export default PasswordModal;
