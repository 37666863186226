import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function CategoryDropdown(props) {

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { target: { value }, } = event;
    props.handleChange(value)
  };

  return (
    <div>
      <FormControl sx={{ width: 280 }} size="small">
        <InputLabel id="demo-simple-select-label" className="text-catelina-blue text-base">Sections</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Sections"
          value={props.category}
          onChange={handleChange}
          className="text-base text-sm"
        >
          {props.items.map((item: string, index: number) => (
            <MenuItem value={item} key={index}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
