import * as React from "react";
import ImagePicker from "./ImagePicker";
import Divider from "@mui/material/Divider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const ConfigGeneral = (props) => {
  return (
    <div className="left-sidebar-gereral">
      <div className="flex flex-col text-black site-name">
        <span className="text-base-2 mb-2">Site name</span>
        <span className="font-normal mb-4">
          A site name is a unique identifier for a website, aiding in easy
          organization and navigation within the dashboard.
        </span>
        <input
          className="border rounded border-catelina-blue/60 w-full py-1 px-2 md:w-80"
          placeholder="Website name"
          value={props.siteName}
          //   disabled
          onChange={(e) => {
            props.onDataChange({ siteName: e.target.value });
          }}
        />
      </div>
      <div className="py-8">
        <Divider />
      </div>

      <ImagePicker
        className="favicon-image"
        image_url={props.faviconImage}
        siteId={props.siteId}
        title="Favicon image"
        desc="Favicon is displayed on your site's tab in a browser."
        notes="Please upload a 16x16px .PNG or .JPG image."
        onImageChange={(img_url) => {
          props.onDataChange({ faviconImage: img_url });
        }}
      />
      <div className="py-8">
        <Divider />
      </div>

      <ImagePicker
        className="og-image"
        siteId={props.siteId}
        image_url={props.defaultSocialMediaImage}
        title="Default social media image (Open graph image)"
        desc="Open Graph images, also known as OG images, are designated images used to represent webpages when shared on social media platforms.
They provide a visual representation of the content and help maintain a consistent and visually appealing appearance across different social media channels."
        notes="Please upload a 1200x630px .PNG or .JPG image."
        onImageChange={(img_url) => {
          props.onDataChange({ defaultSocialMediaImage: img_url });
        }}
      />
      <div className="flex flex-col text-black">
        <span className="text-base-2 mb-2 mt-4">Alt text</span>
        <span className="font-normal mb-2">
        Alt text for the image provide accessibility and additional information for use.
        </span>
        <input
          className="border rounded border-catelina-blue/60 w-full py-1 px-2 md:w-80"
          value={props.defaultSocialMediaImageAlt}
          onChange={(e) => {
            props.onDataChange({ defaultSocialMediaImageAlt: e.target.value });
          }}
        />
        <div className="items-center text-xsm">
          <InfoOutlinedIcon fontSize="inherit" />
          <span className="ml-1">Please enter social media image alt text.</span>
        </div>
      </div>
      <div className="py-8">
        <Divider />
      </div>

      <div className="flex flex-col text-black">
        <span className="text-base-2 mb-2">Default social media description (Open graph description)</span>
        <span className="font-normal mb-4">
        The Open Graph description enhances the appearance of your content when shared on platforms like Facebook and Twitter, helping to engage users and encourage clicks.
        </span>
        <div className="flex flex-row">
          <textarea
            value={props.defaultSocialMediaDescription}
            onChange={(e) => {
              props.onDataChange({defaultSocialMediaDescription: e.target.value})
            }}
            className="w-full lg:w-4/5 border rounded border-catelina-blue/60 py-1 px-2"
            rows={4}
          />
        </div>
        <div className="items-center text-xsm">
          <InfoOutlinedIcon fontSize="inherit" />
          <span className="ml-1 leading-normal">This description will be used across all pages when a page-specific description is not provided. Write a brief, engaging summary (up to 160 characters) that represents your website or brand. It will appear in search results, social media previews (under the 'meta,' 'og,' and 'twitter' tags), and when shared on platforms like Facebook and Twitter.</span>
        </div>
      </div>
    </div>



  );
};

export default ConfigGeneral;