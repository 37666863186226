import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

const CategoryItem = (props) => {
  // const [isChecked, setIsChecked] = React.useState(props.isChecked);
  const onCheckChange = () => {
    // setIsChecked(!isChecked);
    props.onCheckChanged(!props.isChecked);
  };
  const screenWidth = screen.width
  return (
    <div className="flex w-full flex-row md:text-white text-sm font-body">
      <div className="">
        <Checkbox
          checked={props.isChecked}
          onChange={(e) => {
            onCheckChange();
          }}
          size="small"
          sx={{
            color: screenWidth < 744 ? '#3B3B3B' : 'white',
            fontSize: "14px",
            "&.Mui-checked": {
              color: screenWidth < 744 ? '#3B3B3B' : 'white',
            },
          }}
        />
      </div>
      <div
        className="w-full self-center hover:cursor-pointer text-grey-primary md:text-white"
        onClick={() => onCheckChange()}
      >
        {props.item.category}
      </div>
      <div className="w-8 self-center">{props.item.cnt}</div>
      {/* <div className="w-8 self-center hidden" style={{color: '#3B3B3BCC'}}>{props.item.cnt}</div> */}
    </div>
  );
};

export default CategoryItem;
