import * as React from "react";
import Button from "@mui/material/Button";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";

const TemplateItem = (props) => {
  const navigate = useNavigate();
  // console.log("TemplateItem", props);
  const [showOption, setShowOption] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [selectedColorPallet, setSelectedColorPallet] = React.useState({
    ...props.templateColorPalettes[0],
    initial: true,
  });
  React.useEffect(() => {}, [selectedColorPallet]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Grid justifyContent="space-between" item lg={4} md={6} sm={6} xs={12}>
      <div className="flex flex-row justify-between items-center text-black text-sm py-2">
        <span>{props.templateName}</span>
        <div className="flex flex-row items-center">
          {props.templateColorPalettes.map((row) => {
            let colorPallet =
              (props.colorPallets.length > 0 &&
                props.colorPallets.find((oo) => oo.id === row.id)) ||
              null;
            // console.log("for each", colorPallet.colors);
            return colorPallet ? (
              <div
                onClick={() => setSelectedColorPallet(row)}
                key={row.id}
                className={`flex flex-row  color-icon relative  hover:cursor-pointer`}
              >
                <span
                  className={`h-6 w-3 block rounded-l-full ${
                    selectedColorPallet.id === row.id
                      ? "border-y border-l border-black"
                      : "border-y border-l border-gray-100"
                  } `}
                  style={{
                    backgroundColor: colorPallet
                      ? colorPallet.colors[0].toLowerCase()
                      : "",
                  }}
                />
                <span className="icon-tooltip-text brand-color-tooltip">Select brand colors</span>

                <div className="flex flex-col">
                  <span
                    className={`h-3 w-3 block rounded-tr-full ${
                      selectedColorPallet.id === row.id
                        ? "border-t border-r border-black"
                        : "border-t border-r border-gray-100"
                    } `}
                    style={{
                      backgroundColor: colorPallet
                        ? colorPallet.colors[1].toLowerCase()
                        : "",
                    }}
                  />
                  <span
                    className={`h-3 w-3 block rounded-br-full ${
                      selectedColorPallet.id === row.id
                        ? "border-b border-r border-black"
                        : "border-b border-r border-gray-100"
                    } `}
                    style={{
                      backgroundColor: colorPallet
                        ? colorPallet.colors[3].toLowerCase()
                        : "",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div key={row.id} className={`flex flex-row relative`}>
                <span className={`h-6 w-3 block rounded-l-full`} />
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="flex flex-col hover:cursor-pointer relative shadow-lg"
        onMouseOver={() => setShowOption(true)}
        onMouseLeave={() => setShowOption(false)}
      >
        <div className="upper-block flex flex-col ">
          <img src={require("../../images/white-bg-template.png")} />
          {isLoading && <div className="inner-block flex flex-col"></div>}
          {selectedColorPallet.imageUrl ? (
            <>
              {/* {isLoading && (
                <div className="flex items-center justify-center border border-gray mb-12 image-loader-block">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-red" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg> Loading
                </div>
              )} */}
              <img
                className={`${
                  showOption && !isLoading ? "bg-black opacity-30" : ""
                } ${isLoading ? "hidden" : "block"} image-template`}
                src={`${selectedColorPallet.imageUrl}?w=162&auto=format`}
                srcSet={`${selectedColorPallet.imageUrl}?w=162&auto=format&dpr=2 2x`}
                alt={props.templateName}
                loading="lazy"
                onLoad={handleImageLoad}
                style={{
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  display: "block",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)",
                }}
              />
            </>
          ) : (
            <div
              className="min-h-96 flex items-center justify-center"
              style={{ minHeight: "700px" }}
            >
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-red"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>{" "}
              Loading
            </div>
          )}
          {showOption && !isLoading && (
            <div className="absolute w-full h-full  flex justify-center items-center">
              <Stack direction="column" spacing={1}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    color: "#243060",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#243060",
                      color: "#fff",
                    },
                  }}
                  onClick={() => {
                    // console.log("on edit", props);
                    window.location.href = `${
                      process.env.EDITOR_ENDPOINT
                    }/?tid=${props.siteId}&theme=${
                      selectedColorPallet ? selectedColorPallet.id : ""
                    }`;
                    // window.location.reload();
                  }}
                  startIcon={<NoteAltOutlinedIcon />}
                >
                  Edit site
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    color: "#243060",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#243060",
                      color: "#fff",
                    },
                  }}
                  onClick={() => {
                    // window.location.href = props.previewUrl
                    // navigate(`/preview/${props.siteId}`, {
                    //   state: { selectedColorPallet },
                    // });
                    const url = `/preview/${props.siteId}?id=${selectedColorPallet.id}`;
                    window.open(url, "_blank");
                  }}
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                >
                  Preview
                </Button>
              </Stack>
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default TemplateItem;
