import ReceiptSharpIcon from "@mui/icons-material/ReceiptSharp";
import * as React from "react";
import InvoiceItem from "./InvoiceItem";

const ConfigBillings = (props) => {
  return (
    <div className="config-billings flex flex-col justify-center items-left w-full">
      {/* <h2 className="my-4">Billing</h2> */}
      <div className="w-full flex flex-col">
        <div className="w-full">
          {props.isLoading && (
            <div className="bg-white border rounded-lg shadow-md p-4 w-full h-64 flex flex-col justify-center text-center">
              <ReceiptSharpIcon className="self-center mb-2" />
              <h3 className="text-xl font-bold mb-0 mt-0">
                Fetching Invoices...
              </h3>
            </div>
          )}
          {!props.isLoading && props.invoices.length === 0 && (
            <div className="bg-white border rounded-lg shadow-md p-4 w-full h-64 flex flex-col justify-center text-center">
              <ReceiptSharpIcon className="self-center mb-2" />
              <h3 className="text-xl font-bold mb-0 mt-0">No invoices found</h3>
              <p className="text-xsm mb-4 mt-0">
                you haven't generated any invoices so far.
              </p>
            </div>
          )}
          {!props.isLoading && props.invoices.length > 0 && (
            <div className="bg-white border rounded-lg shadow-md p-4 w-full">
              <h3 className="text-lg font-bold mb-4 mt-0">Invoices</h3>
              {props.invoices?.map((invoice: any) => {
                return <InvoiceItem key={invoice.id} data={invoice} />;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigBillings;
