import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CookieConsent from "../components/CookieConsent/CookieConsent";
import { Navbar } from "../components/Layout/Navbar";

const stripePromise = loadStripe(process.env.STRIPE_KEY || "");

export const UpgradeSuccessPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { siteId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState();
  const [subsRes, setSubsRes] = useState(null);
  const search = useLocation().search;
  const session_id = new URLSearchParams(search).get("session_id");

  React.useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        let config = {
          method: "get",
          url: `${process.env.API_ENDPOINT}/api/configs/stripe-subscription-from-session?sessionId=${session_id}`,
          withCredentials: true,
        };
        let res = await axios.request(config);
        // console.log("kk", res.data);

        if (!res.data.subscription && res.data.error) {
          enqueueSnackbar(res.data.error, {
            variant: "error",
            className: "bg-catelina-blue",
          });
          setIsLoading(false);
          return;
        }
       
        setSubsRes(res.data.subscription);
        // setData(rs.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
      // console.log("session_id", session_id);

      // window.location.href = res.data.sessionUrl
    }
    // setIsLoading(true);
    fetchData();
    // setIsLoading(false);
  }, []);

  // Function to convert timestamp to month/date/year format
  function timestampToDateString(timestamp) {
    // Create a new Date object using the timestamp
    const date = new Date(timestamp);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the individual components (month, day, year)
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Create the formatted string
    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
  }

  return (
    <>
      <Helmet>
        <title>RocketPages - Dashboard</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      <div className="font-body text-base font-medium">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Navbar />
        <div className="bg-[#FAFAFA] absolute top-[64px] w-full h-screen">
          <div className=" max-w-2xl bg-white my-12 m-auto rounded-lg px-2 md:px-16 py-6">
            {subsRes && (
              <div className="flex flex-col text-grey-primary">
                <div className="flex flex-col justify-center text-xl font-semibold mb-10 text-center md:mx-20 items-center">
                  <img
                    className="mb-4"
                    src={require("../images/check-icon.svg")}
                    width={64}
                    alt=""
                  />
                  You’ve successfully subscribed to the{" "}
                  {subsRes.plan_desc &&
                    subsRes.plan_desc.replace("Rocket Pages IO", "")}
                  !
                </div>
                <div className="flex justify-between my-2 flex-col md:flex-row">
                  <span className="text-base">Subscription ID</span>
                  <span className="text-sm">{subsRes.subscription ?? subsRes.subscriptionId}</span>
                </div>
                <div className="flex justify-between my-2 flex-col md:flex-row">
                  <span className="text-base">Plan Desc</span>{" "}
                  <span className="text-sm">{subsRes.plan_desc}</span>
                </div>
                <div className="flex justify-between my-2 flex-col md:flex-row">
                  <span className="text-base">Subscription Start</span>{" "}
                  <span className="text-sm">
                    {timestampToDateString(subsRes.subscription_start * 1000)}
                  </span>
                </div>
                <div className="flex justify-between my-2 flex-col md:flex-row">
                  <span className="text-base">Subscription End</span>{" "}
                  <span className="text-sm">
                    {timestampToDateString(subsRes.subscription_end * 1000)}
                  </span>
                </div>
                <div className="flex justify-between my-2">
                  <span className="text-base">
                    Invoice URL {/* <a href={subsRes.invoice_url}> */}
                    <ContentCopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(subsRes.invoice_url);
                        enqueueSnackbar("Invoice url copied", {
                          variant: "info",
                          className: "bg-catelina-blue",
                        });
                      }}
                      className="mx-2 cursor-pointer"
                      sx={{ color: "#243060", fontSize: "16px" }}
                    />
                    {/* </a>{" "} */}
                  </span>
                  {/* <span> {subsRes.invoice_url}</span> */}
                </div>

                <div className="flex justify-center">
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "#243060",
                      color: "#fff",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#243060",
                      },
                    }}
                    onClick={() =>
                      navigate(`/site-settings/${siteId}?section=General`)
                    }
                    className="ml-2"
                  >
                    <span className="lg:block">Site Dashboard</span>
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "#243060",
                      color: "#fff",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#243060",
                      },
                    }}
                    onClick={() => {
                      window.location.href = `${process.env.EDITOR_ENDPOINT}/${siteId}`;
                      // window.location.reload();
                    }}
                    className="ml-2"
                  >
                    <span className="lg:block">Edit site</span>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <span>{JSON.stringify(data, null, 2)}</span> */}
      </div>
      <CookieConsent />
    </>
  );
};