import * as React from "react";
import { Link } from "react-router-dom";
import CookieConsent from "../../components/CookieConsent/CookieConsent";
import styles from "./login.module.css";

const Footer = () => {
  const date = new Date();
  return (
    <>
      <footer className={`${styles.footer} flex justify-between flex-col`}>
        <div
          className={`flex justify-between items-start sm:items-center mb-4 lg:mb-0 flex-col sm:flex-row`}
        >
          <Link to="/" className={`${styles.footerLogo}`}>
            <img src={require("../../images/logo-dark.svg")} alt="" />
          </Link>
          <div className={`${styles.footerText} hidden lg:block px-4`}>
            <span>
              {" "}
              &copy; {date.getFullYear()} RocketPages. All Rights Reserved
            </span>
            <span className="hidden md:inline mx-2">|</span>
            <Link
              to="https://rocketpages.io/terms-and-conditions"

              className="text-white no-underline"
            >
              Terms And Conditions
            </Link>
            <span className="hidden md:inline mx-2">|</span>
            <Link
              to="https://rocketpages.io/privacy-policy"

              className="text-white no-underline"
            >
              Privacy Policy
            </Link>
          </div>
          <div className={`${styles.footerSocials} flex mt-4 sm:mt-0`}>
          <Link
              to="https://www.linkedin.com/company/rocketpages"
              target="_blank"
              className="mr-2"
            >
              <img
                src={require("../../images/footer-linkedin.svg")}
                className="h-[16px] md:h-[24px]"
                alt="linkedin"
              />
            </Link>
            <Link
              to="https://www.facebook.com/RocketPagesIO"
              target="_blank"
              className="mr-2"
            >
              <img
                src={require("../../images/facebook.svg")}
                className="h-[16px] md:h-[24px]"
                alt="facebook"
              />
            </Link>
            <Link
              to="https://twitter.com/RocketPagesIO"
              target="_blank"
            >
              <img
                src={require("../../images/twitter.svg")}
                className="h-[16px] md:h-[24px]"
                alt="twitter"
              />
            </Link>
          </div>
        </div>
        <div className={`GWlAxG_footerText flex footer-div lg:hidden md:flex`}>
          <span className="mb-1">
            &copy; {date.getFullYear()} RocketPages. All Rights Reserved
          </span>

          <span className="mx-2 pipe-1">|</span>
          <span className="flex">
            <Link to="https://rocketpages.io/terms-and-conditions" className="mb-1 text-white">
                Terms And Conditions
              </Link>
              <span className="mx-2">|</span>
              <Link to="https://rocketpages.io/privacy-policy" className="text-white">
                Privacy Policy
              </Link>
          </span>
        </div>
      </footer>
      {/* <CookieConsent /> */}
    </>
  );
};

export default Footer;
