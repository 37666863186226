import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import * as React from "react";
import axiosInstance from "../../axiosInstance";

const SubscriptionCard = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isCreatingUrl, setIsCreatingUrl] = React.useState(false);
  //   console.log("SubscriptionCard", props);
  const s_start = new Date(props.data.subscription_start * 1000);
  const s_end = new Date(props.data.subscription_end * 1000);

  const getStripeCustomerPortalUrl = async () => {
    setIsCreatingUrl(true);
    let response = await axiosInstance.get(
      `/api/configs/site-config-get-stripe-customer-session?siteId=${props.data.siteId}&subscriptionId=${props.data.subscriptionId}`,
    );

    // console.log("getStripeCustomerPortalUrl", response.data);
    setIsCreatingUrl(false);
    if (response.data.error) {
      return enqueueSnackbar(response.data.error, {
        variant: "error",
        autoHideDuration: 9000,
      });
    }

    window.location.href = response.data.url;
  };

  return (
    <div>
      {props.data.subscription_status !== 'past_due' && <div className="mt-4 flex justify-end">
        <Button
          className="shadow-none mr-2 rounded-md"
          disabled={props.data.subscription_status === "canceled" || isCreatingUrl || props.isCancelling || (props.data.cancel_at_period_end && !props.upcomingPhase)}
          size="large"
          variant="contained"
          color="primary"
          onClick={() => getStripeCustomerPortalUrl()}
        >
          <Typography textTransform='capitalize'>Update payment method</Typography>
        </Button>

        <Button
          className="shadow-none rounded-md bg-red-600"
          disabled={props.data.subscription_status === "canceled" || props.isCancelling || (props.data.cancel_at_period_end && !props.upcomingPhase)}
          size="large"
          variant="contained"
          color="error"
          onClick={() => props.onCancel(props.data.subscription)}
        >
          <Typography textTransform='capitalize'>Cancel Subscription</Typography>
        </Button>
      </div>}
    <h4 className="font-bold mb-2 mt-0">Active Plan</h4>
    <div
      className={`bg-white border border-[#E1E4EF] p-4 rounded-md`}
      //   onClick={()=> props.onCardSelectionChange(props.data.subscriptionId)}
    >
      <div className="grid grid-cols-1 gap-2 xl:grid-cols-2 text-xsm sm:text-sm">
        <div className="flex flex-col sm:flex-row">
          <p className="text-gray-600 mb-0 w-full sm:w-2/5">Subscription ID:</p>
          <p className="font-semibold mb-0 w-full sm:w-3/5 text-grey-primary">
            {props.data.subscriptionId}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row">
          <p className="text-gray-600 mb-0 w-full sm:w-2/5">Subscription Status:</p>
          <p className={"font-semibold mb-0 w-full sm:w-3/5" + (props.data.subscription_status === 'past_due' ? ' text-red-600 font-semibold' : ' text-lime-600 font-semibold capitalize')}>
            {props.data.subscription_status === 'past_due' ? 'Pending' : props.data.subscription_status}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row">
          <p className="text-gray-600 mb-0 w-full sm:w-2/5">Plan:</p>
          <p className="font-semibold mb-0 w-full sm:w-3/5 text-grey-primary">{props.data.plan_desc}</p>
        </div>
        <div className="flex flex-col sm:flex-row">
          <p className="text-gray-600 mb-0 w-full sm:w-2/5">Price:</p>
          <p className="font-semibold mb-0 w-full sm:w-3/5 text-grey-primary">
            ${props.data.amount_total / 100}
          </p>
        </div>

        <div className="flex flex-col sm:flex-row">
          <p className="text-gray-600 mb-0 w-full sm:w-2/5">Start Date:</p>
          <p className="font-semibold mb-0 w-full sm:w-3/5 text-grey-primary">
            {s_start.toLocaleDateString("en-GB")}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row">
          <p className="text-gray-600 mb-0 w-full sm:w-2/5">{props.data.cancel_at_period_end || props.data.subscription_status === "canceled" || props.upcomingPhase ? 'End' : 'Renew'} Date:</p>
          <p className="font-semibold mb-0 w-full sm:w-3/5 text-grey-primary">
            {s_end.toLocaleDateString("en-GB")}
          </p>
        </div>

        {/* <div className="flex flex-row">
          <p className="text-gray-600 mb-0 w-2/5">Next Billing At:</p>
          <p className="font-semibold mb-0 w-3/5">Next Billing Date here</p>
        </div> */}
      </div>

      {props.data.subscription_status === 'past_due' && <div className="flex flex-col items-center lg:flex-row justify-between mt-6">
        <div className="bg-[#E1E4EF] rounded p-2 mb-0 flex items-center">
          <p className="text-sm mb-0 flex items-center text-red-600  flex-wrap md:flex-noWrap">
            <InfoOutlinedIcon className="mr-1 text-red-600" style={{ fontSize: 20 }} /> 
            <span className="font-semibold">Transaction incomplete:</span>
            <span className="text-grey-primary ml-1 font-norma text-red-600">Please complete your current plan's payment to activate the features of this plan.</span>
          </p>
        </div>
        <Button
          className="shadow-none ml-2 lg:mt-0 mt-2"
          disabled={!props.latestInvoice}
          size="large"
          variant="contained"
          color="primary"
          onClick={() => window.open(props.latestInvoice.hosted_invoice_url, '_blank')}
        >
          <Typography textTransform='capitalize'>Pay Now</Typography>
        </Button>
      </div>}
      {/* <div className="mt-4 flex justify-end">
        {props.data.subscription_status !== 'past_due' && <><Button
          className="shadow-none mr-2 rounded-md"
          disabled={props.data.cancel_at_period_end || props.data.subscription_status === "canceled" || isCreatingUrl}
          size="large"
          variant="contained"
          color="primary"
          onClick={() => getStripeCustomerPortalUrl()}
        >
          <Typography textTransform='capitalize'>Update payment method</Typography>
        </Button>

        <Button
          className="shadow-none rounded-md bg-red-600"
          disabled={props.data.cancel_at_period_end || props.data.subscription_status === "canceled" || props.isCancelling}
          size="large"
          variant="contained"
          color="error"
          onClick={() => props.onCancel(props.data.subscription)}
        >
          <Typography textTransform='capitalize'>Cancel Subscription</Typography>
        </Button></>}
      </div>  */}
    </div>
    
    
    </div>

  );
};

export default SubscriptionCard;