import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import * as React from "react";
import { useCookies } from "react-cookie";
import styles from "../Auth/login.module.css";
import ContributorItem from "./ContributorItem";

const ConfigContributors = (props) => {
  const [cookies] = useCookies(["rp_user_data"]);
  const [emails, setEmails] = React.useState("");
  const [error, setError] = React.useState("");
  const [addOnUsers, setAddOnUsers] = React.useState(props.addOnUsers || []);

  React.useEffect(() => {
    // console.log("ConfigContributors", props.addOnUsers);
    setAddOnUsers(props.addOnUsers);
  }, [props.addOnUsers]);
  const onSendInvite = () => {
    // Split string by comma into an array
    let l_emails = emails.split(",");
    let l_validEmails: any[] = [];
    var valid = true;
    var regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let invalidEmails: string[] = [];

    for (var i = 0; i < l_emails.length; i++) {
      // Trim whitespaces from email address
      l_emails[i] = l_emails[i].trim();

      // Check email against our regex to determine if email is valid
      // console.log(
      //   "asasas",
      //   cookies.rp_user_data ? cookies.rp_user_data.email : ""
      // );
      if (
        l_emails[i] == "" ||
        !regex.test(l_emails[i]) ||
        l_emails[i] === (cookies.rp_user_data ? cookies.rp_user_data.email : "")
      ) {
        invalidEmails.push(l_emails[i]);
      } else {
        if (
          !addOnUsers.find((kk) => kk.email === l_emails[i]) &&
          !l_validEmails.find((kk) => kk.email === l_emails[i])
        ) {
          l_validEmails.push({ email: l_emails[i], role: "editor" });
        }
      }
    }
    if (invalidEmails.filter((kk) => kk !== "").length > 0) {
      // setError(`Invalid Email Address. Please make sure you've entered a correctly formatted email address (e.g., example@email.com): ${invalidEmails.join(", ")}`);
      setError(
        `The email address "${invalidEmails.join(
          ", "
        )}" is invalid. Please ensure you've entered a correctly formatted email address (e.g., example@email.com)`
      );
    } else {
      setError(``);
      setEmails("");
      let l_onlyEmails: string[] = [];
      l_validEmails.forEach((ii: any) => {
        l_onlyEmails.push(ii.email);
      });
      props.onAddOnUsersAdd(l_onlyEmails);

      setAddOnUsers((old: any) => {
        props.onDataChange({ addOnUsers: [...old, ...l_validEmails] });
        return [...old, ...l_validEmails];
      });
    }
    // console.log(invalidEmails);
  };
  return (
    <div className="flex flex-col text-black h-screen left-sidebar-contributors">
      <div className="flex flex-col">
        <span className="text-base-2 mb-2">Invite contributors</span>
        <span className="font-normal mb-2">
          Contributors can view, edit and publish the shared website. However,
          they cannot delete or duplicate it. You can cancel access at any time.
        </span>
        <div className="flex flex-col lg:flex-row">
          <div className="flex flex-col pb-2 lg:pb-0">
            <input
              className="border rounded border-catelina-blue/60 py-1 px-2 mr-3 w-full md:w-80"
              placeholder="Email, comma separated"
              value={emails}
              onChange={(e) => {
                setEmails(e.target.value);
                setError("");
              }}
            />
          </div>

          <Button
            size="small"
            className="h-8 w-28"
            variant="contained"
            sx={{
              textTransform: "none",
            }}
            onClick={onSendInvite}
            disabled={emails === ""}
          >
            Send invite
          </Button>
        </div>
        {error !== "" && (
          <label className={`${styles.errorLabels}`}>{error}</label>
        )}
        <div className="py-2 w-full max-w-full md:max-w-md">
          <Divider />
        </div>
        <div className="w-full max-w-full md:max-w-md">
          {addOnUsers &&
            addOnUsers.map((row) => {
              return (
                <ContributorItem
                  key={row.email}
                  email={row.email}
                  onRemoveUser={() => {
                    // console.log('onremove user', row.email)
                    props.onAddOnUsersRemove(row.email);
                    let l_new_addonusers = addOnUsers.filter(
                      (ll) => ll.email !== row.email
                    );
                    props.onDataChange({ addOnUsers: l_new_addonusers });
                    setAddOnUsers(l_new_addonusers);
                  }}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ConfigContributors;
