import * as React from "react";
import Switch from "@mui/material/Switch";
import { SketchPicker } from "react-color";
import FormatColorFillOutlinedIcon from "@mui/icons-material/FormatColorFillOutlined";
import FormatColorTextOutlinedIcon from "@mui/icons-material/FormatColorTextOutlined";

const ConfigWidgets = (props) => {
  const [backgroudColor, setBackgroundColor] = React.useState({
    displayColorPicker: false,
    color: props.cookies.cookieBackgroundColor,
  });
  const [fontColor, setFontColor] = React.useState({
    displayColorPicker: false,
    color: props.cookies.cookieFontColor,
  });
  return (
    <div className="flex flex-col text-black h-screen left-sidebar-widgets">
      <div className="flex flex-col">
        <span className="text-base-2 mb-2">
          Cookie consent banner
          <Switch
            checked={props.cookies.cookieBannerVisible}
            onChange={(e) => {
              props.onDataChange({
                cookies: {
                  ...props.cookies,
                  cookieBannerVisible: e.target.checked,
                },
              });
            }}
          />
        </span>
        <span className="font-normal mb-2">
          This will add a cookie policy widget on each page.
        </span>
        <div className="flex flex-col mt-3 text-sm font-normal relative">
          <span>Message</span>
          <input
            className="w-96 border rounded border-catelina-blue/60 py-1 px-2"
            placeholder="We use cookies to improve your experience on our site.  "
            value={props.cookies.cookieText}
            onChange={(e) => {
              props.onDataChange({
                cookies: {
                  ...props.cookies,
                  cookieText: e.target.value,
                },
              });
            }}
          />
        </div>
        <div className="flex flex-col mt-3 text-sm font-normal">
          <span>Button title</span>
          <input
            className="w-96 border rounded border-catelina-blue/60 py-1 px-2"
            placeholder="Okay"
            value={props.cookies.cookieButtonText}
            onChange={(e) => {
              props.onDataChange({
                cookies: {
                  ...props.cookies,
                  cookieButtonText: e.target.value,
                },
              });
            }}
          />
        </div>

        <div className="flex flex-row mt-6 text-sm font-normal items-center w-96 justify-between relative">
          <div>
            <FormatColorFillOutlinedIcon />
            <span className="mx-2">Background color</span>
          </div>

          <div
            style={{
              backgroundColor: `rgba(${backgroudColor.color.r}, ${backgroudColor.color.g}, ${backgroudColor.color.b}, ${backgroudColor.color.a})`,
            }}
            className={`h-8 w-8 rounded border`}
            onClick={() => {
              setBackgroundColor((old) => {
                return { ...old, displayColorPicker: !old.displayColorPicker };
              });
            }}
          />
          {backgroudColor.displayColorPicker ? (
            <div className="absolute z-10 inset-x-60 inset-y-0">
              <div
                className="fixed inset-0"
                onClick={() => {
                  setBackgroundColor((old) => {
                    return { ...old, displayColorPicker: false };
                  });
                }}
              />
              <SketchPicker
                color={backgroudColor.color}
                onChange={(color) => {
                  setBackgroundColor((old) => {
                    return { ...old, color: color.rgb };
                  });
                  props.onDataChange({
                    cookies: {
                      ...props.cookies,
                      cookieBackgroundColor: color.rgb,
                    },
                  });
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="flex flex-row mt-3 text-sm font-normal items-center w-96 justify-between relative">
          <div>
            <FormatColorTextOutlinedIcon />
            <span className="mx-2">Font color</span>
          </div>

          <div
            style={{
              backgroundColor: `rgba(${fontColor.color.r}, ${fontColor.color.g}, ${fontColor.color.b}, ${fontColor.color.a})`,
            }}
            className={`h-8 w-8 rounded border`}
            onClick={() => {
              setFontColor((old) => {
                return { ...old, displayColorPicker: !old.displayColorPicker };
              });
            }}
          />
          {fontColor.displayColorPicker ? (
            <div className="absolute z-10 inset-x-60 inset-y-0">
              <div
                className="fixed inset-0"
                onClick={() => {
                  setFontColor((old) => {
                    return { ...old, displayColorPicker: false };
                  });
                }}
              />
              <SketchPicker
                color={fontColor.color}
                onChange={(color) => {
                  setFontColor((old) => {
                    return { ...old, color: color.rgb };
                  });
                  props.onDataChange({
                    cookies: {
                      ...props.cookies,
                      cookieFontColor: color.rgb,
                    },
                  });
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ConfigWidgets;
