import React, { useState } from "react";
import CookieConsent from "../CookieConsent/CookieConsent";
import Footer from "../Auth/Footer";
import styles from "../PrivacyPolicy/privacypolicy.module.css";
const TermsAndConditions = (props) => {
return (
    <>
    <main>
      <div className={`${styles.contentWrapper}  ${styles.spacing} mt-8`}>
        <section className={ styles.section }>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}>
              <h1 className={`${styles.h1}`}><strong>RocketPages Terms and Conditions</strong></h1>
            </div>
            <div className={`${styles.description}`}>
              <br />
              <p><strong>Last updated: May 17, 2024</strong></p>

              <p>Welcome to RocketPages! These terms and conditions govern your use of our Website Builder. By accessing and using RocketPages, you agree to comply with the following terms and conditions. If you do not agree with any part of these terms, please refrain from using RocketPages.</p>
            </div>
          </div>
      </section>

        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>User Accounts</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>To access certain features of RocketPages, users may be required to create an account. Users are responsible for maintaining the confidentiality of their account credentials and for any activity that occurs under their accounts.</li><li>RocketPages reserves the right to refuse service, terminate accounts, or remove content at our sole discretion.</li></ol>
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>User Roles</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>RocketPages offers various user roles with differing levels of access and permissions. These roles include but are not limited to contributors, who can create and edit content, and owners, who have full control over site management.</li><li>Account owners are responsible for managing user roles and permissions within RocketPages.</li></ol>
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>Site Creation and Duplication</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
              <ol><li>Users can create and duplicate websites using RocketPages Website Builder.</li><li>RocketPages does not claim ownership of any content created by users but may use such content for promotional purposes.</li></ol>
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>Site Publication</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>Users can publish their websites using RocketPages, making them accessible to end-users.</li><li>RocketPages is not responsible for the content published by users and does not endorse any views expressed therein.</li></ol>
            </div>
          </div>
        </section>


        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>Video Embedding and Social Login</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>RocketPages allows users to embed videos from Vimeo and YouTube and utilize social login features within their websites.</li><li>By embedding videos or using social login features, users agree to comply with the terms and conditions of Vimeo, YouTube, and any other applicable third-party platforms.</li></ol>
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>Custom Domain Attachment</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>Users have the option to attach a custom domain to their website on RocketPages.&nbsp;</li></ol>
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>Payment Processing and Subscription
</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>RocketPages offers paid subscription plans for access to premium features. Payment for RocketPages services is handled by Stripe, a third-party payment processor.</li><li>By subscribing to a paid plan, you agree to comply with the terms of service and privacy policy of Stripe.</li><li>RocketPages does not store or process payment information directly and is not responsible for any issues arising from the use of Stripe's services.</li></ol>
            </div>
          </div>
        </section>


        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>Limitation of Liability
</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>RocketPages shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of RocketPages.</li><li>RocketPages does not guarantee the accuracy, completeness, or reliability of any content or information provided on the platform.</li></ol>
            </div>
          </div>
        </section>


        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>Privacy Policy
</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>Your use of RocketPages is subject to our Privacy Policy, which outlines how we collect, use, and disclose your personal information.</li></ol>
            </div>
          </div>
        </section>


        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>Modification of Terms</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>RocketPages reserves the right to modify these terms and conditions at any time without prior notice. Continued use of RocketPages after any such changes shall constitute your consent to such changes.</li></ol>
            </div>
          </div>
        </section>


        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>Governing Law
</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>These terms and conditions shall be governed by and construed in accordance with the laws of California, and any disputes relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of California.</li></ol>
            </div>
          </div>
        </section>


        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong> Acceptance of Terms
</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>By using RocketPages, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you do not agree with any part of these terms and conditions, please refrain from using RocketPages.</li></ol>
            </div>
          </div>
        </section>


        <section className={styles.section}>
          <div>
            <div className={`${styles.title}  ${styles.fsmd}`}><h3><strong>Age Requirements
</strong></h3></div>
            <div className={`${styles.description}  ${styles.fsmd}`}>
            <ol><li>By accessing or using this website, you affirm that you are at least 13 years old or the age of majority in your jurisdiction, whichever is greater. If you are under the specified age, you must obtain consent from your parent or guardian before using this website. Please be aware that if you are located in the European Union, you may need to comply with the General Data Protection Regulation (GDPR), which sets the minimum age for consent at 16 in most cases, though individual EU member states may have different age requirements.</li></ol>
            </div>
          </div>
        </section>

      </div>
       <Footer />
      </main>
      <CookieConsent />
    </>
  );
}

export default TermsAndConditions;
