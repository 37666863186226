import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import "../../index.css";
import { useSnackbar } from "notistack";
import axiosInstance from "../../axiosInstance";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const UserAcquisitionLogs = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState([]);
  const [showSiteData, setShowSiteData] = React.useState([]);

  async function fetchData() {
    try {
      let response = await axiosInstance.get(`/api/admin/user-acqusition-logs`);

      setData(response.data.data);
      // console.log(response.data);
    } catch (err) {}
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full p-4 flex flex-row">
      <div className=" bg-white w-7/12">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow className="p-2">
                <TableCell>User email</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any, idx: any) => {
                const date = new Date(row.createdOn);
                return (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {date.toLocaleString()}
                    </TableCell>

                    <TableCell align="right">
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Button
                          sx={{
                            textTransform: "none",
                          }}
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setShowSiteData(row.sites);
                          }}
                        >
                          View Sites
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="w-5/12 pl-4">
        {showSiteData &&
          showSiteData.map((ee: any) => {
            const _date = new Date(ee.updatedAt);
            return (
              <div className="bg-white p-2 m-1 rounded-lg" key={ee.siteId}>
                <div className="flex flex-row">
                  <span>Site Name: </span>
                  <span>{ee.siteName}</span>
                </div>
                <div className="flex flex-row">
                  <span>Last modified On: </span>
                  <span>{_date.toLocaleString()}</span>
                </div>
                <div className="flex flex-row">
                  <span>Publish Status: </span>
                  <span>{ee.isPublished ? 'true': 'false'}</span>
                </div>
                <div className="flex flex-row">
                  <span>Site URL: </span>
                  <a href={`https://${ee.siteUrl}`} target="_blank">{ee.siteUrl}</a>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UserAcquisitionLogs;
