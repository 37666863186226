import * as React from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const NoSiteExists = (props) => {
  return (
    <div className="flex justify-center py-24 flex-col items-center">
      <img className="h-20" src={require("../../images/no-website.svg")} />
      <span className="text-center">You don’t have any website yet</span>
      <span className="text-center py-4">
        <Button
            size="small"
          variant="contained"
          sx={{ backgroundColor: "#243060", textTransform: "none" }}
          startIcon={<AddCircleIcon sx={{ color: "#fff" }} />}
          onClick={props.onNewWebsiteClick}
        >
          Create new website
        </Button>
      </span>
    </div>
  );
};

export default NoSiteExists;
