import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import "../../index.css";
import { useSnackbar } from "notistack";
import axiosInstance from "../../axiosInstance";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddEdittemplateCategory from "./AddEdittemplateCategory";

const TemplateCategoryList = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [currCategory, setCurrCategory] = React.useState(null);
  const [data, setData] = React.useState([]);

  async function fetchData() {
    try {
      let response = await axiosInstance.get(`/api/configs/all-site-category`);
      setData(
        response.data.data.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        )
      );
    } catch (err) {}
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };
  const handleSave = () => {
    fetchData();
    setOpen(false);
  };

  const handleDelete = async (id) => {
    let res = await axiosInstance.delete(
      `/api/configs/site-categories?name=${id}`
    );
    if (res.status === 200) {
      fetchData();
    } else {
      enqueueSnackbar("something went wrong...!", {
        variant: "error",
      });
    }
  };

  return (
    <div className="p-4 bg-white w-auto max-w-md">
      {open && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="absolute inset-0 bg-gray-900 opacity-50"
            onClick={() => setOpen(false)}
          ></div>
          <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 z-50">
            <AddEdittemplateCategory
              data={data}
              currCategory={currCategory}
              onCancel={handleCancel}
              onSave={handleSave}
            />
          </div>
        </div>
      )}

      <div className="text-black flex flex-row justify-between mb-4">
        <span>List of template categories</span>
        <Button
          sx={{
            textTransform: "none",
          }}
          size="small"
          variant="contained"
          onClick={() => {
            setCurrCategory(null);
            setOpen(true);
          }}
        >
          Add template category
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow className="p-2">
              <TableCell>Category Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any, idx: any) => (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>

                <TableCell align="right">
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      sx={{
                        textTransform: "none",
                      }}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        setCurrCategory(row.name)
                        setOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      sx={{
                        textTransform: "none",
                      }}
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        handleDelete(row.name)
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TemplateCategoryList;