import { Button } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { Navbar } from "../components/Layout/Navbar";
import CookieConsent from "../components/CookieConsent/CookieConsent";
import eye from "../images/bi_eye.svg";
import open_eye from "../images/open_eye.svg";
import "../styles/editor.css";
// import eyeImage from './images/bi_eye.svg';

const AccountSetting = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [updatePasswordRes, setUpdatePasswordRes] = React.useState({
    message: "",
    isVisible: false,
    isError: false,
  });
  const [googleLinkRes, setGoogleLinkRes] = React.useState({
    message: "",
    isVisible: false,
    isError: false,
  });
  const [linkedInLinkRes, setLinkedInLinkRes] = React.useState({
    message: "",
    isVisible: false,
    isError: false,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  const [cookies, setCookie, removeCookie] = useCookies([
    "rp_user_data",
    "rp_user_id",
  ]);
  const [accountSettingData, setAccountSettingData] = React.useState<any>({});
  React.useState<any>([]);
  const styleEnabled = {
    backgroundColor: "#fff",
    border: "1px solid",
    borderColor: "#243060",
    color: "#243060",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#243060",
      color: "#fff",
    },
  };
  const styleDisabled = {
    backgroundColor: "#fff",
    border: "1px solid",
    borderColor: "#ff0000",
    color: "#ff0000",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ff0000",
      color: "#fff",
    },
  };
  const [formData, setFormData] = React.useState({
    currentPassword: "",
    password: "",
    rePassword: "",
  });

  const [formErrors, setFormErrors] = React.useState({
    currentPassword: "",
    password: "",
    rePassword: "",
  });

  const [toggleAttributes, setToggleAttributes] = React.useState({
    currentPassword: "password",
    password: "password",
    rePassword: "password",
  });

  async function fetchAccountSettings(email) {
    let response = await axiosInstance.get(
      `/api/account-settings?email=${email}`
    );
    let prepData = {};
    response.data.linkedAccounts &&
      response.data.linkedAccounts.forEach((row) => {
        if (row.googleSource === true) {
          prepData = {
            ...prepData,
            googleSource: true,
            googleSourceEmail: row.email,
          };
        }
        if (row.linkedInSource === true) {
          prepData = {
            ...prepData,
            linkedInSource: true,
            linkedInSourceEmail: row.email,
          };
        }

        if (row.emailSource) {
          prepData = {
            ...prepData,
            emailSource: true,
            emailSourceEmail: row.email,
          };
        }
      });
    setAccountSettingData({ ...response.data.data, ...prepData });
    // console.log("setAccountSettingData", {
    //   ...response.data.data,
    //   ...prepData,
    // });
  }

  useEffect(() => {
    fetchAccountSettings(
      cookies.rp_user_data ? cookies.rp_user_data.email : ""
    );
  }, []);

  function handleChange(event) {
    setFormData((old) => {
      return { ...old, [event.target.id]: event.target.value.trim() };
    });
    setFormErrors((old) => {
      return { ...old, [event.target.id]: "" };
    });
  }

  function handleTogglePassword(id) {
    setToggleAttributes((old) => {
      return { ...old, [id]: old[id] === "password" ? "text" : "password" };
    });
  }
  function validateForm(formData) {
    try {
      // console.log("formData", formData);
      setFormErrors({ currentPassword: "", password: "", rePassword: "" });

      if (formData.currentPassword === "" && accountSettingData.emailSource) {
        setFormErrors((old) => {
          return {
            ...old,
            currentPassword: "Current password can not be empty",
          };
        });
        return false;
      }

      if (formData.password === "") {
        setFormErrors((old) => {
          return { ...old, password: "New password can not be empty" };
        });
        return false;
      }

      if (formData.rePassword === "") {
        setFormErrors((old) => {
          return { ...old, rePassword: "Re-password can not be empty" };
        });
        return false;
      }

      if (formData.password.length < 8) {
        setFormErrors((old) => {
          return {
            ...old,
            password: "New password length can not be less than 8 chars.",
          };
        });
        return false;
      }

      if (formData.password !== formData.rePassword) {
        setFormErrors((old) => {
          return { ...old, rePassword: "Re-password does not match password." };
        });
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  const onUpdatePassword = async () => {
    // console.log("onUpdatePassword");
    if (validateForm(formData)) {
      try {
        axiosInstance
          .post("/api/update-account-password", {
            email: cookies.rp_user_data.email,
            currentPassword: formData.currentPassword,
            newPassword: formData.password,
          })
          .then((response) => {
            setUpdatePasswordRes({
              message: response.data.message,
              isVisible: true,
              isError: false,
            });
          })
          .catch((err) => {
            setUpdatePasswordRes({
              message: err.response.data.message,
              isVisible: true,
              isError: true,
            });
          })
          .finally(() => {
            setUpdatePasswordRes((old) => {
              if (!old.isError) {
                setFormData({
                  currentPassword: "",
                  password: "",
                  rePassword: "",
                });
              }
              return { ...old };
            });
            setTimeout(() => {
              setUpdatePasswordRes((old) => {
                if (!old.isError) {
                  setFormData({
                    currentPassword: "",
                    password: "",
                    rePassword: "",
                  });
                }
                return { ...old, isVisible: false };
              });
              fetchAccountSettings(
                cookies.rp_user_data ? cookies.rp_user_data.email : ""
              );
            }, 5000);
          });
      } catch (err) {
        console.log("onUpdatePassword", err);
      }
    } else {
      console.log("false", formErrors);
    }
  };

  const handleLogout = () => {
    async function logoutUser() {
      try {
        // console.log("before.remove.cookies");

        setCookie("rp_user_data", "", {
          maxAge: 0,
          domain:
            process.env.DOMAIN ||
            window.location.hostname.split(".").slice(1).join("."),
        });
        setCookie("rp_user_id", "", {
          maxAge: 0,
          domain:
            process.env.DOMAIN ||
            window.location.hostname.split(".").slice(1).join("."),
        });

        await axiosInstance.post("/api/logout", {}, { withCredentials: true });
      } catch (err) {
        console.log(err);
      }
    }

    logoutUser();
    // removeCookie("rp_access_token");
    navigate("/login", { state: { removeCookies: true } });

    // removeCookie_userData("rp_user_data");
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.LINKEDIN_CLIENT_ID || "",
    scope: process.env.LINKEDIN_SCOPE || "",
    redirectUri: process.env.LINKEDIN_REDIRECT_URI || "", // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      onLoginViaLinkedIn(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  async function onLoginViaLinkedIn(authCode) {
    setIsLoading(true);
    try {
      await axiosInstance
        .post(`api/link-social-login`, {
          userId: cookies.rp_user_data.userId,
          email: cookies.rp_user_data.email,
          socialAccountType: "linkedIn",
          authCode: authCode,
        })
        .then((response) => {
          setLinkedInLinkRes({
            message: "Linked Successfully!",
            isVisible: true,
            isError: false,
          });

          fetchAccountSettings(
            cookies.rp_user_data ? cookies.rp_user_data.email : ""
          );
        })
        .catch((err) => {
          setLinkedInLinkRes({
            message: err.response.data.message,
            isVisible: true,
            isError: true,
          });
        })
        .finally(() => {
          setTimeout(() => {
            setLinkedInLinkRes((old) => {
              return { ...old, isVisible: false };
            });
          }, 5000);
        });

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  async function unLinkAccount(type) {
    setIsLoading(true);
    try {
      await axiosInstance
        .post(`api/unlink-social-login`, {
          email:
            type === "google"
              ? accountSettingData.googleSourceEmail
              : accountSettingData.linkedInSourceEmail,
          socialAccountType: type,
        })
        .then((response) => {
          // console.log('active email',response.data.data.email);
          if (response.status === 200) {
            setCookie("rp_user_data", JSON.stringify(response.data.data), {
              maxAge: 60 * 60 * 24 * 7,
              domain:
                process.env.DOMAIN ||
                window.location.hostname.split(".").slice(1).join("."),
            });

            fetchAccountSettings(response.data.data.email);
          }
        })
        .catch((err) => {
          if (type === "google") {
            setGoogleLinkRes({
              message: err.response.data.message,
              isVisible: true,
              isError: true,
            });
          } else if (type === "linkedIn") {
            setLinkedInLinkRes({
              message: err.response.data.message,
              isVisible: true,
              isError: true,
            });
          }
        })
        .finally(() => {
          setTimeout(() => {
            if (type === "google") {
              setGoogleLinkRes((old) => {
                return { ...old, isVisible: false };
              });
            } else if (type === "linkedIn") {
              setLinkedInLinkRes((old) => {
                return { ...old, isVisible: false };
              });
            }
          }, 5000);
        });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  const googleLinkAccount = useGoogleLogin({
    hint: cookies.rp_user_data ? cookies.rp_user_data.email : "",
    onSuccess: async (codeResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${codeResponse.access_token}` } }
      );
      const result = userInfo.data;
      // console.log("result", result);
      await axiosInstance
        .post(`api/link-social-login`, {
          userId: cookies.rp_user_data.userId,
          email: cookies.rp_user_data.email,
          socialAccountType: "google",
          googleRes: result,
        })
        .then((response) => {
          setGoogleLinkRes({
            message: "Linked Successfully!",
            isVisible: true,
            isError: false,
          });

          fetchAccountSettings(
            cookies.rp_user_data ? cookies.rp_user_data.email : ""
          );
        })
        .catch((err) => {
          setGoogleLinkRes({
            message: err.response.data.message,
            isVisible: true,
            isError: true,
          });
        })
        .finally(() => {
          setTimeout(() => {
            setGoogleLinkRes((old) => {
              return { ...old, isVisible: false };
            });
          }, 5000);
        });
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <>
      <Helmet>
        <title>Account Settings | RocketPages IO</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      <div className="font-body text-base font-medium account-settings">
        <Navbar {...stateData} title={"Account settings"} />
        <div className="my-4 pl-[20px]  pb-10 pt-[64px] bg-white-smoke pr-[20px]">
          <div className="lg:w-auto xl:w-8/12 m-auto bg-white px-4 lg:px-24 py-8">
            <div className="pb-[30px] sm:pb-8 border-b border-slate-300 email">
              <label style={{ color: "#243060" }}>Email</label>
              <div className="flex justify-between items-center">
                <span
                  className="text-sm sm:text-base break-all font-normal"
                  style={{ color: "#3B3B3B" }}
                >
                  {cookies.rp_user_data ? cookies.rp_user_data.email : ""}
                </span>
              </div>
            </div>
            <div className="py-5 sm:py-8 border-b border-slate-300 password">
              <label style={{ color: "#243060" }}>Password</label>
              <div>
                <label
                  className="text-sm font-normal"
                  style={{ color: "#3B3B3B" }}
                >
                  Current password
                </label>
                <div className="relative mb-3">
                  <input
                    className={`pw-field appearance-none border ${
                      formErrors.currentPassword !== "" ? "border-red-500" : ""
                    } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    id="currentPassword"
                    type={toggleAttributes.currentPassword}
                    // placeholder={!accountSettingData.emailSource ? "set pa password" : ""}
                    value={formData.currentPassword}
                    onChange={handleChange}
                    disabled={!accountSettingData.emailSource}
                  />
                  {formData.currentPassword !== "" && (
                    <button
                      tabIndex={-1}
                      type="button"
                      id="toggleCurrentPassword"
                      onClick={() => handleTogglePassword("currentPassword")}
                    >
                      <img
                        src={
                          toggleAttributes.currentPassword === "text"
                            ? eye
                            : open_eye
                        }
                        alt="Show/Hide Password"
                        id="eye-icon"
                      />
                    </button>
                  )}
                  {formErrors.currentPassword !== "" && (
                    <label className={`text-red-500 text-xsm`}>
                      {formErrors.currentPassword}
                    </label>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-4 mt-3 mb-6">
                <div>
                  <label
                    className="text-sm font-normal"
                    style={{ color: "#3B3B3B" }}
                  >
                    New password
                  </label>

                  <div className="relative mb-3">
                    <input
                      className={`pw-field pw-position appearance-none border ${
                        formErrors.password !== "" ? "border-red-500" : ""
                      } rounded w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline`}
                      id="password"
                      type={toggleAttributes.password}
                      placeholder=""
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {formData.password !== "" && (
                      <button
                        tabIndex={-1}
                        type="button"
                        id="togglePassword"
                        onClick={() => handleTogglePassword("password")}
                      >
                        <img
                          src={
                            toggleAttributes.password === "text"
                              ? eye
                              : open_eye
                          }
                          alt="Show/Hide Password"
                          id="eye-icon"
                        />
                      </button>
                    )}
                    {formErrors.password !== "" && (
                      <label className={`text-red-500 text-xsm leading-3`}>
                        {formErrors.password}
                      </label>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    className="text-sm font-normal"
                    style={{ color: "#3B3B3B" }}
                  >
                    Repeat password
                  </label>
                  <div className="relative mb-3">
                    <input
                      className={`pw-field pw-position appearance-none border ${
                        formErrors.rePassword !== "" ? "border-red-500" : ""
                      } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                      id="rePassword"
                      type={toggleAttributes.rePassword}
                      placeholder=""
                      value={formData.rePassword}
                      onChange={handleChange}
                    />
                    {formData.rePassword !== "" && (
                      <button
                        tabIndex={-1}
                        type="button"
                        id="toggleRePassword"
                        onClick={() => handleTogglePassword("rePassword")}
                      >
                        <img
                          src={
                            toggleAttributes.rePassword === "text"
                              ? eye
                              : open_eye
                          }
                          alt="Show/Hide Password"
                          id="eye-icon"
                        />
                      </button>
                    )}
                    {formErrors.rePassword !== "" && (
                      <label className={`text-red-500 text-xsm`}>
                        {formErrors.rePassword}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <Button
                className="w-full"
                variant="contained"
                size="small"
                onClick={onUpdatePassword}
                sx={{
                  backgroundColor: "#243060",
                  border: "1px solid",
                  borderColor: "#243060",
                  color: "#fff",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#243060",
                    color: "#fff",
                  },
                }}
              >
                Save
              </Button>
              {updatePasswordRes.isVisible && (
                <div className="mt-2">
                  <span
                    className={`${
                      updatePasswordRes.isError
                        ? "text-red-500"
                        : "text-green-500"
                    }`}
                  >
                    {updatePasswordRes.message}
                  </span>
                </div>
              )}
            </div>
            <div className="pt-5 social-logins">
              <label
                style={{
                  color: "#243060",
                  marginBottom: "24px",
                  display: "flex",
                }}
              >
                Social logins
              </label>

              {/* Google */}
              <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center mb-4">
                <div className="flex items-start">
                  <img
                    style={{ marginRight: "16px", marginTop: "8px" }}
                    src={require("../images/google.svg")}
                    alt=""
                  />
                  <div className="flex flex-col">
                    <label style={{ fontWeight: 600, color: "#3B3B3B" }}>
                      Google account
                    </label>
                    <p
                      style={{ color: "#3B3B3B" }}
                      className="mb-0 font-normal"
                    >
                      {accountSettingData.googleSource &&
                      accountSettingData.googleSource === true
                        ? "Enabled "
                        : "Disabled "}
                      {/* ({accountSettingData.googleSourceEmail}) */}
                      {accountSettingData.googleSourceEmail &&
                        accountSettingData.googleSourceEmail !==
                          accountSettingData.email && (
                          <span>({accountSettingData.googleSourceEmail})</span>
                        )}
                    </p>
                  </div>
                </div>

                {/* <GoogleLogin
                  onSuccess={async (credentialResponse) => {
                    onLoginViaGoogle(credentialResponse);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  type="icon"
                  scope={""}
                  // shape="square"
                  // size="large"
                  // width="40"
                /> */}
                <div className="flex ml-8 mt-2 sm:ml-0 sm:mt-0">
                  {googleLinkRes.isVisible && (
                    <div className="mt-2">
                      <span
                        className={`text-xsm mx-2 ${
                          googleLinkRes.isError
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {googleLinkRes.message}
                      </span>
                    </div>
                  )}
                  <Button
                    className="px-4 py-2 w-24"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      if (
                        accountSettingData.googleSource &&
                        accountSettingData.googleSource === true
                      ) {
                        unLinkAccount("google");
                      } else {
                        googleLinkAccount();
                      }
                    }}
                    sx={
                      accountSettingData.googleSource &&
                      accountSettingData.googleSource === true
                        ? styleDisabled
                        : styleEnabled
                    }
                  >
                    {accountSettingData.googleSource &&
                    accountSettingData.googleSource === true
                      ? "Disable"
                      : "Enable"}
                  </Button>
                </div>
              </div>

              {/* LinkedIn */}
              <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center mb-4">
                <div className="flex items-start">
                  <img
                    style={{ marginRight: "16px", marginTop: "8px" }}
                    src={require("../images/LinkedIn.svg")}
                    alt=""
                  />
                  <div className="flex flex-col">
                    <label style={{ fontWeight: 600, color: "#3B3B3B" }}>
                      LinkedIn account
                    </label>
                    <p
                      style={{ color: "#3B3B3B" }}
                      className="mb-0 font-normal"
                    >
                      {accountSettingData.linkedInSource &&
                      accountSettingData.linkedInSource === true
                        ? "Enabled "
                        : "Disabled "}
                      {/* {accountSettingData.linkedInSourceEmail !== accountSettingData.email && (({accountSettingData.linkedInSourceEmail}))} */}
                      {accountSettingData.linkedInSourceEmail &&
                        accountSettingData.linkedInSourceEmail !==
                          accountSettingData.email && (
                          <span>
                            ({accountSettingData.linkedInSourceEmail})
                          </span>
                        )}
                    </p>
                  </div>
                </div>
                <div className="flex ml-8 mt-2 sm:ml-0 sm:mt-0">
                  {linkedInLinkRes.isVisible && (
                    <div className="mt-2">
                      <span
                        className={`text-xsm mx-2 ${
                          linkedInLinkRes.isError
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {linkedInLinkRes.message}
                      </span>
                    </div>
                  )}
                  <Button
                    className="px-4 py-2 w-24"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      if (
                        accountSettingData.linkedInSource &&
                        accountSettingData.linkedInSource === true
                      ) {
                        unLinkAccount("linkedIn");
                      } else {
                        linkedInLogin();
                      }
                    }}
                    sx={
                      accountSettingData.linkedInSource &&
                      accountSettingData.linkedInSource === true
                        ? styleDisabled
                        : styleEnabled
                    }
                  >
                    {accountSettingData.linkedInSource &&
                    accountSettingData.linkedInSource === true
                      ? "Disable"
                      : "Enable"}
                  </Button>
                </div>
              </div>
            </div>

            {/* <div className="py-5">
              <label style={{ color: "#243060" }}>Logout</label>
              <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
                <span className="font-normal">Log out of this device</span>
                <Button
                  className="w-full sm:w-40 py-2 mt-4 sm:mt-0"
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#243060",
                    border: "1px solid",
                    borderColor: "#243060",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#243060",
                      color: "#fff",
                    },
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <CookieConsent />
    </>
  );
};

export default AccountSetting;
