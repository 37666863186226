import axios from "axios";

const axiosInstance = axios.create({ baseURL: process.env.API_ENDPOINT, withCredentials: true });

// Add an interceptor to include the access token in the request headers
axiosInstance.interceptors.request.use(
  (config) => {
    // console.log("axios instance". config)
    // const accessToken = localStorage.getItem("accessToken");
    // if (accessToken) {
    //   config.headers["Authorization"] = `Bearer ${accessToken}`;
    // }
    // config.headers["Access-Control-Allow-Origin"] = `*`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    // Handle response errors
    return Promise.reject(error);
  }
);

export default axiosInstance;
