import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axiosInstance from "../../axiosInstance";
import DNSProviders from "../DNSProviders";

const CustomDomainModal = ({
  siteId,
  domainName,
  isOpen,
  onClose,
  showStepper,
  isPointDNS,
}) => {
  const [domain, setDomain] = useState(domainName);
  const [isValid, setIsValid] = useState(false);
  const [showValidattionError, setShowValidationError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  // const [isSubDomain, setIsSubDomain] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);
  const [stepperActive, setStepperActive] = useState(showStepper);
  const [showPointDNS, setShowPointDNS] = useState(isPointDNS);
  const [caddyServerIP, setCaddyServerIP] = useState("143.198.78.133");
  const steps = ["Add domain", "Point DNS"];

  // Function to handle subdomain input change
  const handleSubdomainChange = (event) => {
    setDomain(event.target.value);
    const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Change the pattern as needed
    const isValidDomain = domainPattern.test(event.target.value);
    setIsValid(isValidDomain);

    setShowValidationError(false);
  };

  // Function to handle form submission
  const onAddDomain = async (event) => {
    event.preventDefault();
    setShowValidationError(!isValid);
    if (isValid) {
      // onClose(domain);
      let data: {} = { siteId };
      try {
        data = { ...data, domain: domain, isPrimary: isPrimary };
        await axiosInstance.put("/api/configs/custom-domain", data);
        if (isChecked) {
          data = { ...data, domain: `www.${domain}`, isPrimary: false };
          await axiosInstance.put("/api/configs/custom-domain", data);
        }
        setStepperActive(true);
        setShowPointDNS(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getCaddyServerIP = () => {
    let currentURL = window.location.href;
    if (currentURL.includes("app.rocketpages.io")) {
      setCaddyServerIP("146.190.156.136");
    } else {
      setCaddyServerIP("143.198.78.133");
    }
  };

  React.useEffect(() => {
    return () => {
      setDomain("");
      setIsValid(false);
      setShowValidationError(false);
      //setStepperActive(false);
      //setShowPointDNS(false);
      setIsChecked(false);
      getCaddyServerIP();
    };
  }, [isOpen]);

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
    >
      {stepperActive && (
        <div className="flex items-center justify-center min-h-screen p-4">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          {showPointDNS && (
            <div className="bg-slate-100 rounded-lg p-4 sm:p-8 max-w-2xl w-full mx-auto z-20 add-domain-popup-3">
              <div className="bg-white rounded-lg p-4 sm:p-8 max-w-2xl w-full mx-auto z-20">
                <h2 className="text-lg font-semibold mb-2 mt-0">
                  Update your DNS records at your DNS provider
                </h2>

                <p className="text-xsm mb-0">
                  Create a records for <b>{domain}</b>
                  {isChecked && (
                    <span>
                      {" "}
                      and <b>www.{domain}</b>
                    </span>
                  )}{" "}
                  with your domain provider so they can direct traffic to your
                  site.
                </p>

                <DNSProviders domain={domain} caddyServerIP={caddyServerIP} />

                {/* <h2 className="text-sm font-semibold mb-2 mt-0">A Records</h2>
                <p className="text-xsm mb-0">{ caddyServerIP }</p> */}
                {/* <p className="text-xsm mb-0">192.168.1.2</p> */}

                {/* <h2 className="text-lg font-semibold mb-2 mt-0">
                  How to point DNS
                </h2>
                <p className="text-xsm mb-0">
                  To update your DNS details at your domain provider, go to your
                  domain provider then copy and paste the DNS records into the
                  appropriate fields.
                </p>

                <a className="text-xsm mb-0" href="#">
                  Learn more about pointing DNS
                </a> */}

                <div className="flex justify-end mt-6 flex-col sm:flex-row">
                  <Button
                    id="save"
                    className="sm:w-32 py-1 z-0 mb-2.5 sm:mb-0 h-[52] sm:h-auto"
                    sx={{
                      textTransform: "none",
                    }}
                    size="small"
                    variant="contained"
                    onClick={onClose}
                  >
                    Ok I've done this
                  </Button>

                  <button
                    onClick={onClose}
                    className="sm:ml-2 px-4 py-2 border rounded-md hover:bg-gray-200 focus:outline-none focus:ring focus:border-blue-300"
                  >
                    I'll do this later
                  </button>
                </div>
              </div>
            </div>
          )}
          {!showPointDNS && (
            <div className="bg-slate-100 rounded-lg p-4 sm:p-8 max-w-2xl w-full mx-auto z-20 add-domain-popup-2">
              <Stepper activeStep={0} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <div className="bg-white rounded-lg p-4 sm:p-8 max-w-2xl w-full mx-auto z-20 mt-6">
                <h2 className="text-lg font-semibold mb-2 mt-0">
                  Your domain has been added to your environment.
                </h2>
                <p className="text-xsm mb-0">
                  The final step when taking your site live is to update your
                  DNS records. This will route web traffic from your domain to
                  your site hosted on Rocket Pages IO's server. Most of the time
                  you will update these records at your domain registrar - where
                  your domain was purchased.
                </p>

                <div className="flex justify-end mt-6 flex-col sm:flex-row">
                  <Button
                    id="save"
                    className="sm:w-32 py-1 z-0 mb-2.5 sm:mb-0 h-[52] sm:h-auto"
                    sx={{
                      textTransform: "none",
                    }}
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setStepperActive(true);
                      setShowPointDNS(true);
                    }}
                  >
                    Point DNS
                  </Button>

                  <button
                    onClick={onClose}
                    className="sm:ml-2 px-4 py-2 border rounded-md hover:bg-gray-200 focus:outline-none focus:ring focus:border-blue-300"
                  >
                    I'll do this later
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {!stepperActive && (
        <div className="flex items-center justify-center min-h-screen p-4 add-domain-popup-1">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg p-4 sm:p-8 max-w-2xl w-full mx-auto z-20">
            <h2 className="text-xl font-semibold mb-2 mt-0">
              What is your domain (URL)?
            </h2>
            <p className="text-sm font-semibold mb-0">Domain</p>
            <p className="text-xsm  mb-0">e.g. mydomain.com</p>
            <div>
              <div className="mb-4">
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  // placeholder="Enter subdomain"
                  value={domain}
                  onChange={handleSubdomainChange}
                />
                {showValidattionError && !isValid && domain !== "" ? (
                  <p
                    className={`text-red-500 ${domain === "" ? "hidden" : ""}`}
                  >
                    Please enter a valid domain name.
                  </p>
                ) : null}
                {/* {isValid && domain.substring(0, 3) !== "www" && ( */}
                <div className="flex flex-row mt-2 -ml-2">
                  <Checkbox
                    disabled={!(isValid && domain.substring(0, 3) !== "www")}
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    size="small"
                  />
                  <p
                    className={`mb-0 self-center ${
                      isValid && domain.substring(0, 3) !== "www"
                        ? "cursor-pointer"
                        : ""
                    } text-xsm`}
                    onClick={() => {
                      if (isValid && domain.substring(0, 3) !== "www") {
                        setIsChecked(!isChecked);
                      }
                    }}
                  >{`Also add www.${domain} ?`}</p>
                </div>
                <div className="flex flex-row -ml-2">
                  <Checkbox
                    // disabled={!(isValid && domain.substring(0, 3) !== "www")}
                    checked={isPrimary}
                    onChange={() => setIsPrimary(!isPrimary)}
                    size="small"
                  />
                  <p
                    className={`mb-0 self-center cursor-pointer text-xsm`}
                    onClick={() => {
                      setIsPrimary(!isPrimary);
                    }}
                  >{`Set this domain as primary domain`}</p>
                </div>
                {/* )} */}
              </div>
              <div className="flex justify-end mt-4 sm:mt-6 flex-col sm:flex-row">
                <Button
                  id="save"
                  className="sm:w-32 py-1 z-0 mb-2.5 sm:mb-0 h-[52] sm:h-auto"
                  sx={{
                    textTransform: "none",
                  }}
                  size="small"
                  variant="contained"
                  onClick={onAddDomain}
                >
                  Add domain
                </Button>

                <button
                  onClick={onClose}
                  className="sm:ml-2 px-4 py-2 border rounded-md hover:bg-gray-200 focus:outline-none focus:ring focus:border-blue-300"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDomainModal;
