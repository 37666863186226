import * as React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const ContributorItem = (props) => {
  return (
    <div className="flex flex-row justify-between my-2 items-center">
      <span className="text-sm sm:text-base">{props.email}</span>
      <Button
        size="small"
        variant="outlined"
        className="hidden sm:block"
        sx={{
          textTransform: "none",
        }}
        onClick={props.onRemoveUser}
      >
        Remove
      </Button>
      <IconButton aria-label="delete" className="sm:hidden" size="small">
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

export default ContributorItem;
