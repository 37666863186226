import * as React from "react";
import styles from "../login.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer";
import { useCookies } from "react-cookie";
import axiosInstance from "../../../axiosInstance";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CookieConsent from "../../CookieConsent/CookieConsent";
import { useSnackbar } from "notistack";
// import Swal from "sweetalert2";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// //
// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// });

const ResetPassword = () => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [cookies, setCookie] = useCookies(["rp_access_token"]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = React.useState({
    password: "",
    rePassword: "",
  });
  const [formErrors, setFormErrors] = React.useState({
    password: "",
    rePassword: "",
  });
  const isFormInvalid =
    formErrors.password !== "" ||
    formErrors.rePassword !== "" ||
    formData.password === "" ||
    formData.rePassword === "";
 
  //onUpdatePassword
  function validateForm(formData) {
    try {
      // console.log("formData", formData);
      setFormErrors({ password: "", rePassword: "" });
      if (formData.password === "") {
        setFormErrors((old) => {
          return { ...old, password: "New password can not be empty" };
        });
        return false;
      }

      if (formData.rePassword === "") {
        setFormErrors((old) => {
          return { ...old, rePassword: "New re-password can not be empty" };
        });
        return false;
      }

      if (formData.password.length < 8) {
        setFormErrors((old) => {
          return {
            ...old,
            password: "New password length can not be less than 8 chars.",
          };
        });
        return false;
      }

      if (formData.rePassword.length < 8) {
        setFormErrors((old) => {
          return {
            ...old,
            rePassword: "New re-password length can not be less than 8 chars.",
          };
        });
        return false;
      }

      if (formData.rePassword.length < 8) {
        setFormErrors((old) => {
          return {
            ...old,
            rePassword: "New re-password length can not be less than 8 chars.",
          };
        });
        return false;
      }

      if (formData.password !== formData.rePassword) {
        setFormErrors((old) => {
          return { ...old, rePassword: "Re-password does not match password." };
        });
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  }
  async function onUpdatePassword(e, formData) {
    e.preventDefault();
    if (!validateForm(formData)) {
      return;
    }
    setIsLoading(true);
    // Swal.fire("Good job!", "You clicked the button!", "success");
    // return;

    let accessToken = cookies.rp_access_token || token;
    try {
      let data = {
        password: formData.password,
      };

      let res = await axiosInstance.post("api/update-password", data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      // setCookie("rp_access_token", res.data.token, { path: "/", maxAge: 60*60*24*7, domain: process.env.DOMAIN || window.location.hostname.split('.').slice(1).join(".") });
      setIsLoading(true);
      enqueueSnackbar("Password has been updated! Please login again", {
        variant: "info",
        className: "bg-grey-400",
      });
      // await Swal.fire("Sucess!", "Password updated sucessfully!", "success");
      navigate("/login", { state: { token: res.data.token } });
    } catch (err) {
      setErrorMessage(err.message);
      // await Swal.fire({
      //   icon: 'error',
      //   title: 'Error',
      //   text: err.message,
      // })
      setIsLoading(false);
    }
  }
  function handleChange(event) {
    setFormData((old) => {
      return { ...old, [event.target.id]: event.target.value };
    });
    setFormErrors({ password: "", rePassword: "" });
    setErrorMessage("");
  }
  return (
    <>
      <main className={`${styles.forgotPassword}`}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          This is a success message!
        </Alert>
      </Snackbar> */}
        <header
          className={`${styles.headerMobile} md:hidden flex justify-between items-center`}
        >
          <Link to="/" className={`${styles.logoLight}`}>
            <img src={require("../../../images/rocket-logo.svg")} alt="" />
          </Link>
        </header>

        <section className={`${styles.contentContaienr} flex justify-start`}>
          <div className={`${styles.leftContent} hidden md:inline-block`}>
            <div className={`${styles.desktopHeader}`}>
              <Link to="/" className={`${styles.logoDark} `}>
                <img src={require("../../../images/logo-dark.svg")} alt="" />
              </Link>
            </div>
            <img
              src={require("../../../images/login/bg-tab.svg")}
              className="hidden xl:block"
            />
            <img
              src={require("../../../images/login/bg-tab-small.svg")}
              className="small-img xl:hidden"
            />
          </div>
          <div className={`${styles.content} md:flex md:flex-col`}>
            <div className={`${styles.formContainer}`}>
              <h1 className={`${styles.heading} mt-0 mb-2`}>Reset Password </h1>
              <div>
                <form
                  className={`${styles.loginForm}`}
                  onSubmit={(e) => {
                    onUpdatePassword(e, formData);
                  }}
                >
                  <div className={`${styles.emailRow} flex flex-col`}>
                    <label htmlFor="email">Enter New Password</label>
                    <input
                      type="password"
                      id="password"
                      onChange={handleChange}
                      value={formData.password}
                      placeholder="Enter new password"
                    />
                    {formErrors.password !== "" && (
                      <label className={`${styles.errorLabels}`}>
                        {formErrors.password}
                      </label>
                    )}
                  </div>
                  <div className={`${styles.emailRow} flex flex-col`}>
                    <label htmlFor="email">Confirm Password</label>
                    <input
                      type="password"
                      id="rePassword"
                      onChange={handleChange}
                      value={formData.rePassword}
                      placeholder="Re-enter new password"
                    />
                    {formErrors.rePassword !== "" && (
                      <label className={`${styles.errorLabels}`}>
                        {formErrors.rePassword}
                      </label>
                    )}
                  </div>

                  {errorMessage !== "" && (
                    <span className="flex text-center mb-2">
                      <ErrorOutlineIcon
                        fontSize="small"
                        sx={{ color: "red" }}
                      />
                      <label className={`${styles.errorLabelForm} `}>
                        {errorMessage}
                      </label>
                    </span>
                  )}

                  <button
                    // onClick={onUpdatePassword}
                    className={`${styles.primaryBtn} ${styles.loginBtn} ${isFormInvalid ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={isFormInvalid}
                  >
                    Update Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
      <CookieConsent />
    </>
  );
};

export default ResetPassword;
