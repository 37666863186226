.loginPage, .signup, .forgotPassword, .forgotPassword {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  max-width: 1440px;
  margin: auto;
  min-height: 70vh;
  font-family: "Poppins", sans-serif;
}

.headerMobile {
  padding: 16px;
}

/*.logoLight img {
  width: 150px;
}*/

.primaryBtn {
  padding: 8px 12px;
  background: #243460;
  color: #ffffff;
  border-radius: 2px;
  font-weight: 600;
  border: 2px solid #243460;
  transition: 0.5s all;
}

.primaryBtn:hover {
  border: 2px solid #243460;
  background-color: transparent;
  color: #243460;
}

.signUpBtn {
  font-size: 14px;
}

.formContainer {
  padding: 16px;
}
.content {
  padding: 40px 16px;
}
.seperatorLine {
  flex: 1;
  height: 2px;
  background-color: #243460;
}

.seperatorText {
  padding: 0px 16px;
  color: #3b3b3b;
  font-weight: 600;
}

.loginForm input {
  color: #3B3B3B;
  padding: 12px 22px;
  border-radius: 0px;
  background-color: #ffffff;
  outline: none;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  border: 1px solid #000000;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-align: justify;
}

.loginForm label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #243460;
}

.errorLabels {
  color: red !important;
  font-size: 12px !important;
  margin-top: 2px;
  opacity: 70%;
}

.errorLabelForm {
  color: red !important;
  font-size: 14px !important;
  margin-top: 2px;
  opacity: 100%;
  margin-left: 4px;
}

.emailRow,
.passwordRow,
.rememberMeRow {
  margin-bottom: 30px;
}

.loginForm input:focus {
  outline: none;
}

.loginBtn {
  font-family: "Poppins", sans-serif;
  width: 100%;
  cursor: pointer;
  padding: 16px;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 16px;
}
.socialSection {
  width: 90px;
  margin: auto;
  margin-top: 25px;
}
.socialIcons {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.contentContaienr {
  width: 100%;
  flex-grow: 1;
}
.content {
  flex: 1;
  max-width: 100%;
}
.formContainer {
  width: 100%;
  max-width: 450px;
  margin: auto;
}

.leftContent {
  background-color: #243460;
  position: relative;
}
.leftContent img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}

.desktopHeader {
  position: relative;
  height: 100px;
}
.dontHaveAccount {
  color: #3b3b3b;
  font-weight: 600;
  font-size: 14px;
  margin-right: 20px;
}
.footer {
  background-color: #243460;
  padding: 32px 16px;
  color: white;
}
.footerText {
  font-size: 12px;
}
.footerLogo img {
  width: 150px;
}

.heading {
  font-weight: 600;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}
.subHeading {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.normalLink {
  color: #243460;
}
.leftContent img {
  width: 100%;
  height: 80%;
  position: absolute;
  bottom: 0;
  right: 0;
  object-fit: cover;
}
.logoDark img {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 200px;
  object-fit: contain;
  height: auto;
}
@media screen and (min-width: 744px) {
  .footerLogo img {
    width: 150px;
  }
  .leftContent {
    max-height: 100%;
  }
  .contentContaienr {
    width: 100%;
  }
  .desktopHeader {
    height: 100px;
    padding-right: 32px;
  }

  .content {
    padding: 0px 0px 40px 0px;
  }
  .leftContent {
    width: 40%;
  }
}

.inputMessage {
  font-size: 14px;
  margin-top: 10px;
  color: #000000;
}


@media screen and (min-width: 1028px) {
}

@media screen and (min-width: 1280px) {
  .leftContent {
    width: 50%;
  }
}
@media screen and (max-width: 1279px) {
  .leftContent img {
    object-fit: fill;
    min-height: unset;
    max-height: 80%;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
}
}

.nopadding {
  padding: 0;
}

.iframecontainer {
  padding-top: 10px;
  max-width: 450px;
  margin: 0 auto;
}
.iframecontainer {
  width: 90%;
  max-width: 100%;
}
@media screen and (max-width: 1024px) {
  .loginForm input {
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .formContainer {
    margin: 0 auto;
  }
  .content {
    padding: 0px 16px;
  }
  .formContainer {
    width: 90%;
  }
  .iframecontainer {
    padding:0
  }
  .formContainer {
    padding: 16px 0;
  }

}
