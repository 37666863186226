import React, { useEffect, useState } from "react"
// import { useRouter } from "next/router"
import clsx from "clsx"
import fetcher from "../../utils/client/fetch"
import { set as setStorage } from "../../utils/client/localstorage"
import ProgressBar from "@ramonak/react-progress-bar"

const PROGRESS_TIME = 120 //SECONDS
const FORM_ID = "queryForm"
type formDataProps = {
  name: string
  business_type: string
  customer_type: string
  products: string
  purpose: string
  services: string
}
type Props = {
  toggleForm: () => void
}

const StepForm: React.FC<Props> = ({ toggleForm }) => {
  // const router = useRouter()
  const [activeStep, setActiveStep] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")
  const [formData, setFormData] = useState<formDataProps>({
    name: "",
    business_type: "",
    customer_type: "",
    products: "",
    purpose: "",
    services: "",
  })
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [progress, setProgress] = useState(0)

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    setFormData((formData: formDataProps) => {
      return {
        ...formData,
        [name]: e.target.value,
      }
    })
  }
  const nextPrev = (n: number) => {
    if (n === 1 && activeStep < 2) {
      const isValidated = validateFields(activeStep)
      if (!isValidated) {
        return false
      }
    }
    if (activeStep + n > 2) {
      setIsFormSubmitted(true)
      startProgress()
      submitForm()
    }
    setActiveStep(activeStep + n)
  }
  const validateFields = (step: number) => {
    if (step == 0) {
      if (formData.name === "" || formData.business_type === "") {
        setErrorMessage("Please answer all queries.")
        return false
      } else {
        setErrorMessage("")
        return true
      }
    }
    if (step == 1) {
      if (formData.purpose === "" || formData.customer_type === "") {
        setErrorMessage("Please answer all queries.")
        return false
      } else {
        setErrorMessage("")
        return true
      }
    }
  }

  const startProgress = () => {
    let intervalHandle = window.setInterval(() => {
      setProgress((progress) => {
        if (progress === PROGRESS_TIME) window.clearInterval(intervalHandle)
        return Math.min(progress + 1, PROGRESS_TIME)
      })
    }, 1000)
  }

  const submitForm = async () => {
    const websiteInfo = {
      name: formData.name.trim(),
      organization: formData.business_type === "organization",
      objective: formData.purpose?.trim(),
      target: formData.customer_type?.trim(),
      services: formData.services?.trim(),
      products: formData.products?.trim(),
    }

    const websiteCopy = await fetcher("/api/generate-copy", {
      body: websiteInfo,
    })

    setStorage("websiteInfo", websiteInfo)
    setStorage("websiteCopy", websiteCopy)
    // router.push("/editor")
  }

  return (
    <>
      <form id={FORM_ID}>
        <i
          className="fa-solid fa-xmark text-[24px] font-bold closeForm"
          onClick={toggleForm}
        ></i>
        {!isFormSubmitted && (
          <div className="progress">
            <span
              className={clsx("step", {
                active: activeStep == 0,
              })}
            ></span>
            <span
              className={clsx("step", {
                active: activeStep == 1,
              })}
            ></span>
            <span
              className={clsx("step", {
                active: activeStep == 2,
              })}
            ></span>
          </div>
        )}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {activeStep === 0 && (
          <div className="tab">
            <div className="question">
              <p>What is the name of your website?</p>
              <div className="input-field">
                <input
                  placeholder="Type here..."
                  name="name"
                  type="text"
                  value={formData?.name}
                  onChange={(e) => handleInputChange(e, "name")}
                />
              </div>
            </div>
            <div className="question">
              <p>Are you operating as an individual or an organization?</p>
              <div className="input-field">
                <input
                  id="individual"
                  value="individual"
                  type="radio"
                  checked={formData?.business_type === "individual"}
                  name="business_type"
                  onChange={(e) => handleInputChange(e, "business_type")}
                />
                <label htmlFor="individual">Individual</label>
              </div>
              <div className="input-field">
                <input
                  id="organization"
                  value="organization"
                  type="radio"
                  checked={formData?.business_type === "organization"}
                  name="business_type"
                  onChange={(e) => handleInputChange(e, "business_type")}
                />
                <label htmlFor="organization">Organization</label>
              </div>
            </div>
          </div>
        )}
        {activeStep === 1 && (
          <div className="tab">
            <div className="question">
              <p>What is the main purpose or objective of your website?</p>
              <div className="input-field">
                <textarea
                  placeholder="Type here..."
                  name="purpose"
                  value={formData?.purpose}
                  onChange={(e) => handleInputChange(e, "purpose")}
                  rows={5}
                ></textarea>
              </div>
            </div>
            <div className="question">
              <p>
                Who is your target audience? Please describe your ideal customer
                or visitor?
              </p>
              <div className="input-field">
                <textarea
                  placeholder="Type here..."
                  name="customer_type"
                  value={formData?.customer_type}
                  onChange={(e) => handleInputChange(e, "customer_type")}
                  rows={5}
                ></textarea>
              </div>
            </div>
          </div>
        )}
        {activeStep === 2 && (
          <div className="tab">
            <div className="question">
              <p>Do you offer any services? If yes, please describe them.</p>
              <div className="input-field">
                <textarea
                  placeholder="Type here..."
                  name="services"
                  value={formData?.services}
                  onChange={(e) => handleInputChange(e, "services")}
                  rows={5}
                ></textarea>
              </div>
            </div>
            <div className="question">
              <p>Do you offer any products? If yes, please describe them</p>
              <div className="input-field">
                <textarea
                  placeholder="Type here..."
                  name="products"
                  value={formData?.products}
                  onChange={(e) => handleInputChange(e, "products")}
                  rows={5}
                ></textarea>
              </div>
            </div>
          </div>
        )}

        {!isFormSubmitted && (
          <div className="actions">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {activeStep > 0 && (
                <button
                  className="btn-info"
                  type="button"
                  id="prevBtn"
                  onClick={() => nextPrev(-1)}
                >
                  Previous
                </button>
              )}
              <button
                className="btn-info"
                type="button"
                id="nextBtn"
                onClick={() => nextPrev(1)}
              >
                {activeStep === 2 ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        )}
        {isFormSubmitted && (
          <div>
            <div className="info-message">Generating your website...</div>
            <ProgressBar
              completed={Math.round((progress / PROGRESS_TIME) * 100)}
            />
          </div>
        )}
      </form>
    </>
  )
}

export default StepForm
