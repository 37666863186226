/* html {
  font-size: 1rem;
} */
h1 {
  font-size: 2rem;
}
.contentWrapper {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  max-width: 90rem;
  margin: 0 auto;
}
.spacing {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.description {
  margin: 0;
  color: #3b3b3b;
  font-family: "Poppins";
}
section {
  font-size: 0.8rem;
}
.title,
.h1 {
  margin: 0;
  color: #243060;
  font-family: "Poppins";
}
.fsmd {
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-weight: normal;
}
