import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import StepForm from "../components/StepForm/StepForm";
import CookieConsent from "../components/CookieConsent/CookieConsent";
import { useNavigate } from "react-router-dom";

export const Homepage = () => {
  let [isFormVisible, setIsFormVisible] = useState(false);
  const navigate = useNavigate();
  // Get the current year dynamically
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    navigate("/choose-template");
  },[])
  return (
    <>
      <Helmet>
        <title>RocketPages</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      <div
        className="is-boxed has-animations is-loaded"
        style={{ height: "100%" }}
      >
        <div
          className={`body-wrap boxed-container ${
            isFormVisible ? "wrapper-blur" : ""
          }`}
        >
          <header className="site-header">
            <div className="container">
              <div className="site-header-inner">
                <div className="brand header-brand">
                  <h1 className="m-0">
                    <a href="#">
                      <img
                        className="header-logo-image asset-light"
                        src={require("../images/rocketpages-logo.svg")}
                        width={200}
                        height={30}
                        alt="Logo"
                      />
                    </a>
                  </h1>
                </div>
              </div>
            </div>
          </header>
          <main>
            <section className="hero">
              <div className="container">
                <div className="hero-inner">
                  <div className="hero-copy">
                    <h1 className="hero-title mt-0">
                      Build and Launch Your Free Website in Just 15 Minutes
                    </h1>
                    <p className="hero-paragraph">
                      With our free website building platform, you can create a
                      stunning website Rocket fast, complete with high-quality
                      content generated automatically by our powerful ChatGPT
                      integration.
                    </p>
                    <div className="hero-cta">
                      <a
                        className="button button-primary"
                        onClick={() => setIsFormVisible(!isFormVisible)}
                      >
                        Build My Free Website Now!
                      </a>
                      <a
                        className="button button-primary"
                        href="/choose-template"
                      >
                        Choose Template
                      </a>
                    </div>
                  </div>
                  <div className="hero-media">
                    <div className="header-illustration">
                      <img
                        className="header-illustration-image asset-light"
                        src={require("../images/header-illustration-light.svg")}
                        width={1440}
                        height={324}
                        alt="Header illustration"
                      />
                    </div>
                    <div className="hero-media-illustration">
                      <img
                        className="hero-media-illustration-image asset-light"
                        src={require("../images/hero-media-illustration-light.svg")}
                        width={733}
                        height={749}
                        alt="Hero media illustration"
                      />
                      {/* <img
                        className="hero-media-illustration-image asset-dark"
                        src={img_hero_media_illustration_dark}
                        width={733}
                        height={749}
                        alt="Hero media illustration"
                      /> */}
                    </div>
                    <div className="hero-media-container">
                      <img
                        className="hero-media-image asset-light"
                        src={require("../images/hero-media-light.svg")}
                        width={1}
                        height={1}
                        alt="Hero media"
                      />
                      <img
                        className="hero-media-image asset-dark"
                        src={require("../images/hero-media-dark.svg")}
                        width={1}
                        height={1}
                        alt="Hero media"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="features section">
              <div className="container">
                <div className="features-inner section-inner has-bottom-divider">
                  <div className="features-header text-center">
                    <div className="container-sm">
                      <h2 className="section-title mt-0">
                        Simple 2-Step Process
                      </h2>
                      <ul>
                        <li className="step-list">
                          <p>
                            Fill in the 5 question Wizard and click on “Generate
                            Website”
                          </p>
                        </li>
                        <li className="step-list">
                          <p>Customize the website template to your needs</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="features-header section-inner second-feature-header text-center">
                    <div className="container-sm">
                      <h2 className="section-title mt-0">
                        The Easiest Way to Build a Website for Free
                      </h2>
                    </div>
                  </div>
                  <div className="features-wrap">
                    <div className="feature is-revealing">
                      <div className="feature-inner">
                        <div className="feature-content">
                          <h3 className="feature-title mt-0">Easy to Use</h3>
                          <p className="text-sm mb-0">
                            It&apos;s no rocket science! Create your website
                            quickly with our user-friendly editor & ChatGPT
                            integration that generates content fast.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="feature is-revealing">
                      <div className="feature-inner">
                        <div className="feature-content">
                          <h3 className="feature-title mt-0">Rocket Fast</h3>
                          <p className="text-sm mb-0">
                            Create your website in no time - our platform
                            promises fast loading speed and a hassle-free
                            website building process.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="feature is-revealing">
                      <div className="feature-inner">
                        <div className="feature-content">
                          <h3 className="feature-title mt-0">
                            Unique Automated Content
                          </h3>
                          <p className="text-sm mb-0">
                            Say goodbye to generic website content. Our platform
                            creates unique content every time, ensuring that
                            your site stands out!
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="feature is-revealing">
                      <div className="feature-inner">
                        <div className="feature-content">
                          <h3 className="feature-title mt-0">SEO Optimized</h3>
                          <p className="text-sm mb-0">
                            Get discovered easily on search engines! Our
                            platform creates websites that are optimized for
                            SEO.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="feature is-revealing">
                      <div className="feature-inner">
                        <div className="feature-content">
                          <h3 className="feature-title mt-0">
                            Customizable Templates
                          </h3>
                          <p className="text-sm mb-0">
                            Choose from a wide range of customizable templates
                            to create a website that truly reflects your brand
                            and personality.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="feature is-revealing">
                      <div className="feature-inner">
                        <div className="feature-content">
                          <h3 className="feature-title mt-0">
                            Mobile-Optimized Design
                          </h3>
                          <p className="text-sm mb-0">
                            Our templates are optimized for mobile devices,
                            ensuring that your website looks great on any
                            device, anywhere, anytime.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="cta section">
              <div className="container-sm">
                <div className="cta-inner section-inner">
                  <div className="cta-header text-center">
                    <h2 className="section-title mt-0">Ready to Start?</h2>
                    <p className="section-paragraph">
                      Don&apos;t wait any longer to create your online presence.
                      Start building your website for free now!
                    </p>
                    <div className="cta-cta">
                      <a
                        className="button button-primary"
                        onClick={() => setIsFormVisible(!isFormVisible)}
                      >
                        Start Building My Free Website Now!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <footer className="site-footer has-top-divider">
            <div className="container">
              <div className="site-footer-inner">
                <div className="brand footer-brand">
                  <a href="#">
                    <img
                      className="asset-light"
                      src={require("../images/rocketpages-logo-light.svg")}
                      width={200}
                      height={30}
                      alt="RocketPages"
                    />
                  </a>
                </div>
                <ul className="footer-social-links list-reset">
                  <li>
                    <Link
                      to="https://www.facebook.com/RocketPagesIO"
                      target="_blank"
                      className="mx-2"
                    >
                      <i className="fa-brands fa-square-facebook text-[20px] text-[white]"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://twitter.com/RocketPagesIO"
                      target="_blank"
                      className="mx-2"
                    >
                      <i className="fa-brands fa-twitter text-[20px] text-[white]"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/rocketpages"
                      target="_blank"
                      className="mx-2"
                    >
                      <i className="fa-brands fa-linkedin text-[20px] text-[white]"></i>
                    </Link>
                  </li>
                </ul>
                <div className="footer-copyright">
                  &copy; { currentYear } RocketPages. All Rights Reserved.
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      {isFormVisible && (
        <div className="stepform-wrapper">
          <StepForm
            toggleForm={() => {
              setIsFormVisible(!isFormVisible);
            }}
          />
        </div>
      )}
      <CookieConsent />
    </>
  );
};
