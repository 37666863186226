export const set = async (key: string, value: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.error(`error setting localstorage ${key}: ${e}`)
  }
}

export const get = async (key: string) => {
  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : null
  } catch (e) {
    console.error(`error getting localstorage ${key}: ${e}`)
  }
}

export const remove = async (key: string) => {
  try {
    return window.localStorage.removeItem(key)
  } catch (e) {
    console.error(`error deleting localstorage ${key}: ${e}`)
  }
}
