import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "./consent.css";

import Stack from "@mui/material/Stack";

const CookieConsent = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["rp_consent"]);

  const acceptCookieConsent = () => {
    removeCookie('rp_consent');
    const datestamp = new Date().toString();

    const cookieValue = JSON.stringify({
      consent: true,
      datestamp: datestamp,
    });
    setCookie('rp_consent', cookieValue, { maxAge: 30 * 24 * 60 * 60 }); // expires in 30 days
    document.getElementById("cookieNotice").style.display = "none";
  };

  const deniedCookieConsent = () => {
    removeCookie('rp_consent');
    const datestamp = new Date().toString();

    const cookieValue = JSON.stringify({
      consent: false,
      datestamp: datestamp,
    });
    setCookie('rp_consent', cookieValue, { maxAge: 30 * 24 * 60 * 60 }); // expires in 30 days
    document.getElementById("cookieNotice").style.display = "none";
  };

  useEffect(() => {
    initCookieConsent();
  }, []);

  const initCookieConsent = () => {
    const cookieConsent = cookies.rp_consent;
    const cookieNotice = document.getElementById("cookieNotice");
    if (cookieConsent !== undefined) {
      cookieNotice.style.display = "none";
    } else {
      cookieNotice.style.display = "flex";
    }
  };

  const cookieConsentTemplate = () => {
    return (
      <div id="cookieNotice" className="cookieNotice fixed bottom-0 w-full z-50 flex flex-col  md:flex-row justify-between gap-4 px-5 py-5 text-white">
        <div className="flex flex-col">
          <div className="title-wrap text-base font-semibold">Your privacy</div>
          <div className="content-wrap">
          <div className="msg-wrap justify-between flex gap-4">
            <span className="text-sm">
              By clicking “Accept all cookies”, you agree RocketPages can store cookies on your device and disclose information in accordance with our <a href="https://rocketpages.io/privacy-policy" className="underline text-white">Cookie Policy</a>
            </span>
          </div>
        </div>
        </div>
        <div className="btn-wrap items-center gap-15 flex">
              <button
               className="btn-accept btn cursor-pointer rounded inline-block font-semibold text-sm leading-4 bg-white" onClick={acceptCookieConsent}>
                Accept all cookies
              </button>
              <button
               className="btn-denied btn cursor-pointer rounded inline-block font-semibold text-sm leading-4 text-white" onClick={deniedCookieConsent}>
                Cancel
              </button>
            </div>
      </div>
    );
  };

  return <>{cookieConsentTemplate()}</>;
};

export default CookieConsent;

