import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import { Navbar } from "../components/Layout/Navbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CookieConsent from "../components/CookieConsent/CookieConsent";
// import axiosInstance from "../axiosInstance";
// import { useNavigate } from "react-router-dom";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import CheckoutForm from "../components/Upgrade/Checkoutform";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";

// const stripePromise = loadStripe(process.env.STRIPE_KEY || "");

export const UpgradeFailPage = () => {
  const location = useLocation();
  // const stateData = location.state;
  const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState();
  // const [subsRes, setSubsRes] = useState(null);
  const search = useLocation().search;
  // const session_id = new URLSearchParams(search).get("session_id");

  return (
    <>
      <Helmet>
        <title>RocketPages - Dashboard</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      <div className="font-body text-base font-medium">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Navbar />
        <div className="bg-red-300 h-full absolute top-[64px] w-full">
          <div className=" m-auto h-20 bg-white-smoke">
            failed
          </div>
          
        </div>
      </div>
      <CookieConsent />
    </>
  );
};
