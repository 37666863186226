import * as React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Navbar } from "../components/Layout/Navbar";
import CookieConsent from "../components/CookieConsent/CookieConsent";

import "../styles/editor.css";

export const DNSProviderPage = (props) => {
  const location = useLocation();
  const stateData = location.state;

  return (
    <>
      <Helmet>
        <title>RocketPages - DNS Service Providers</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      <div className="font-body text-base font-medium dns-service-providers">
        <Navbar {...stateData} title={"DNS Service Providers"} />
        <div className="my-4 pl-[20px]  pb-10 pt-[64px] bg-white-smoke pr-[20px] text-black font-normal">
          <div className="lg:w-auto xl:w-8/12 m-auto bg-white px-4 lg:px-24 py-8">
            <h3>GoDaddy</h3>
            <ol>
              <li>
                <b>Sign In to GoDaddy:</b>
                <ul>
                  <li>Log in to your GoDaddy account.</li>
                </ul>
              </li>
              <li>
                <b>Access Domain Settings:</b>
                <ul>
                  <li>Navigate to "My Products."</li>
                  <li>Find your domain and click on "DNS" or "Manage."</li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Scroll down to the "Records" section.</li>
                  <li>Click "Add" to create a new DNS record.</li>
                  <li>Select "A" as the record type.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Name:</b> "[YourDomainName]"
                      </li>
                      <li>
                        <b>Value:</b> 146.190.156.136
                      </li>
                      <li>
                        <b>TTL:</b> Default
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>Click the pencil icon next to the record to edit it.</li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "Value" field to 146.190.156.136).
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
            </ol>

            <h3>Cloudflare</h3>
            <ol>
              <li>
                <b>Sign In to Cloudflare:</b>
                <ul>
                  <li>Log in to your Cloudflare account.</li>
                </ul>
              </li>
              <li>
                <b>Access DNS Settings:</b>
                <ul>
                  <li>Select the domain you want to manage.</li>
                  <li>Click on the "DNS" tab.</li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Click "Add record."</li>
                  <li>Select "A" as the record type.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Name:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>IPv4 address:</b> 146.190.156.136
                      </li>
                      <li>
                        <b>TTL:</b> Auto
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>Click on the record to edit it.</li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "IPv4 address" field to 146.190.156.136).
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
            </ol>

            <h3>Amazon Route 53</h3>
            <ol>
              <li>
                <b>Sign In to AWS Management Console:</b>
                <ul>
                  <li>Go to the AWS Management Console and log in.</li>
                </ul>
              </li>
              <li>
                <b>Access Route 53:</b>
                <ul>
                  <li>Navigate to Amazon Route 53.</li>
                  <li>Click on "Hosted Zones."</li>
                </ul>
              </li>
              <li>
                <b>Select Domain:</b>
                <ul>
                  <li>Choose the domain you want to manage.</li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Click "Create Record Set."</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Name:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>Type:</b> A - IPv4 address
                      </li>
                      <li>
                        <b>Value:</b> 146.190.156.136
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>Click on the record to edit it.</li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "Value" field to 146.190.156.136).
                  </li>
                  <li>Click "Save Record Set."</li>
                </ul>
              </li>
            </ol>

            <h3>Google Domains</h3>
            <ol>
              <li>
                <b>Sign In to Google Domains:</b>
                <ul>
                  <li>Go to Google Domains and log in to your account.</li>
                </ul>
              </li>
              <li>
                <b>Manage Domain:</b>
                <ul>
                  <li>Locate your domain and click on "Manage" or "DNS."</li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Scroll down to the "Custom resource records" section.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Name:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>Type:</b> A
                      </li>
                      <li>
                        <b>TTL:</b> 1h
                      </li>
                      <li>
                        <b>Data:</b> 146.190.156.136
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>
                    Click on the pencil icon next to the record to edit it.
                  </li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "Data" field to 146.190.156.136).
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
            </ol>

            <h3>Namecheap</h3>
            <ol>
              <li>
                <b>Sign In to Namecheap:</b>
                <ul>
                  <li>Log in to your Namecheap account.</li>
                </ul>
              </li>
              <li>
                <b>Manage Domain:</b>
                <ul>
                  <li>Select the domain you want to manage.</li>
                  <li>Click on "Manage" next to the domain.</li>
                </ul>
              </li>
              <li>
                <b>Access Advanced DNS:</b>
                <ul>
                  <li>Click on the "Advanced DNS" tab.</li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Scroll down to the "Host Records" section.</li>
                  <li>Click "Add New Record."</li>
                  <li>Select "A Record" as the type.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Host Record:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>Points To:</b> 146.190.156.136
                      </li>
                      <li>
                        <b>TTL:</b> Automatic
                      </li>
                    </ul>
                  </li>
                  <li>Click the checkmark to save.</li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>
                    Click on the pencil icon next to the record to edit it.
                  </li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "IP Address" field to 146.190.156.136).
                  </li>
                  <li>Click the checkmark to save.</li>
                </ul>
              </li>
            </ol>

            <h3>Bluehost</h3>
            <ol>
              <li>
                <b>Sign In to Namecheap:</b>
                <ul>
                  <li>Log in to your Bluehost account.</li>
                </ul>
              </li>
              <li>
                <b>Access Domain Manager:</b>
                <ul>
                  <li>
                    From the Bluehost dashboard, click on "Domains" and then "My
                    Domains."
                  </li>
                </ul>
              </li>
              <li>
                <b>Manage DNS:</b>
                <ul>
                  <li>
                    Click the "Manage" button next to the domain you want to
                    update.
                  </li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Go to the "DNS" tab.</li>
                  <li>Scroll down to the "Add DNS Record" section.</li>
                  <li>Select "A" as the record type.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Host Record:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>Points To:</b> 146.190.156.136
                      </li>
                      <li>
                        <b>TTL:</b> Default
                      </li>
                    </ul>
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>Click "Edit" next to the record.</li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "Points To" field to 146.190.156.136).
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
            </ol>

            <h3>HostGator</h3>
            <ol>
              <li>
                <b>Sign In to HostGator:</b>
                <ul>
                  <li>Log in to your HostGator account.</li>
                </ul>
              </li>
              <li>
                <b>Access Domain Manager:</b>
                <ul>
                  <li>
                    From the HostGator dashboard, click on "Domains" and then
                    "My Domains."
                  </li>
                </ul>
              </li>
              <li>
                <b>Manage DNS:</b>
                <ul>
                  <li>
                    Click the "Manage" button next to the domain you want to
                    update.
                  </li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Go to the "DNS" tab.</li>
                  <li>Scroll down to the "Add DNS Record" section.</li>
                  <li>Select "A" as the record type.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Host Record:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>Points To:</b> 146.190.156.136
                      </li>
                      <li>
                        <b>TTL:</b> Default
                      </li>
                    </ul>
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>Click "Edit" next to the record.</li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "Points To" field to 146.190.156.136).
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
            </ol>

            <h3>Final Steps for All Providers</h3>
            <ul>
              <li>
                <b>Wait for Propagation:</b>
                <ul className="list-square">
                  <li>Allow up to 24-48 hours for DNS changes to propagate.</li>
                </ul>
              </li>
              <li>
                <b>Test Your Domain:</b>
                <ul className="list-square">
                  <li>
                    Visit your domain to ensure it is correctly pointing to your
                    hosting provider.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <CookieConsent />
    </>
  );
};
