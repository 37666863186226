import * as React from "react";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import CustomDomainModal from "./CustomDomainModal";
import axiosInstance from "../../axiosInstance";
import Divider from "@mui/material/Divider";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";

const ConfigDomain = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState(props.selectedDomainTab);
  const open = Boolean(anchorEl);
  const [moreOptionMenu, setMoreOptionMenu] = React.useState<any>();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    props.onTabChange(newValue);
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [domains, setDomains] = React.useState<any[]>([]);
  const [domainWiseCount, setDomainWiseCount] = React.useState<any[]>([]);
  const [reloadDomains, setReloadDomains] = React.useState(false);
  const [currentDomainName, setCurrentDomainName] = React.useState("");
  const [isPointDNS, setIsPointDNS] = React.useState(false);
  const [isStepper, setIsStepper] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [textClassObj] = React.useState([
    { fontSize: "14px", color: "#243060" },
    {
      "&:hover": {
        fontWeight: 700,
      },
    },
  ]);
  const handleClick = (event: React.MouseEvent<HTMLElement>, domain) => {
    // console.log('sssss', domain, isValidated, isPrimary)
    setMoreOptionMenu(domain);
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    async function fetchDomainData(siteId) {
      let response = await axiosInstance.get(
        `/api/configs/site-domains?siteId=${siteId}`
      );
      // console.log("//", response.data);
      setDomains(response.data.data);
      let domainWiseCount: any[] = [];
      response.data.data.forEach((row) => {
        let idx = domainWiseCount.findIndex(
          (kk) => kk.domain === row.domain.replace("www.", "")
        );
        if (idx === -1) {
          domainWiseCount.push({
            domain: row.domain.replace("www.", ""),
            cnt: 1,
          });
        } else {
          domainWiseCount[idx].cnt += 1;
        }
      });
      // console.log("domainWiseCount", domainWiseCount);
      setDomainWiseCount(domainWiseCount);
    }
    fetchDomainData(props.siteId);
  }, [isOpen, reloadDomains]);

  // Function to handle domain validation
  const handleValidate = async (domain: string) => {
    props.setIsCheckingSSL(true);

    let response = await axiosInstance.post(
      `/api/configs/validate-custom-domain`,
      { siteId: props.siteId, domain }
    );
    if (response.data.data.isValidated === "true") {
      if (props.isPublished) {
        handleCheckSSL(domain);
      } else {
        setReloadDomains(!reloadDomains);
        props.setIsCheckingSSL(false);
        enqueueSnackbar("Domain validated successfully!", {
          variant: "info",
          className: "bg-grey-400",
        });
      }
    } else {
      props.setIsCheckingSSL(false);
      enqueueSnackbar("Domain validation failed!", {
        variant: "error",
        className: "bg-grey-400",
      });
    }
  };

  const handleCheckSSL = async (domain) => {
    let checkInterval: any = setInterval(async () => {
      const sslResponse = await axiosInstance.get(
        `/api/configs/check-ssl?url=https://${domain}`
      );
      if (sslResponse.status === 200) {
        clearInterval(checkInterval);
        checkInterval = false;
        setReloadDomains(!reloadDomains);
        props.setIsCheckingSSL(false);
        enqueueSnackbar("Domain validated successfully!", {
          variant: "info",
          className: "bg-grey-400",
        });
      } else if (sslResponse.status === 500) {
        clearInterval(checkInterval);
        checkInterval = false;
        props.setIsCheckingSSL(false);
        enqueueSnackbar("Domain validation failed!", {
          variant: "info",
          className: "bg-grey-400",
        });
      }
    }, 5000);

    setTimeout(() => {
      if (checkInterval) {
        clearInterval(checkInterval);
        checkInterval = false;
        props.setIsCheckingSSL(false);
        enqueueSnackbar("Domain validation failed!", {
          variant: "info",
          className: "bg-grey-400",
        });
      }
    }, 120 * 1000);
  };

  const handleSetPrimaryDomain = (domainName) => {
    async function setPrimaryCustomDomain(siteId, domain) {
      props.setIsLoading(true);

      let response = await axiosInstance.post(
        `/api/configs/set-primary-domain`,
        { siteId, domain }
      );
      if (response.status === 200) {
        if (props.isPublished)
          props.checkPublishStatus("Primary domain set successfully!");
        setReloadDomains(!reloadDomains);
      } else {
        props.setIsLoading(false);
      }
    }
    // console.log("onsetprimarydomain", props.siteId, domainName);
    setPrimaryCustomDomain(props.siteId, domainName);
  };

  const handleDelete = (domainName) => {
    async function deleteCustomDomain(siteId, domain) {
      try {
        props.setIsLoading(true);

        let response = await axiosInstance.post(
          `/api/configs/delete-custom-domain`,
          { siteId, domain }
        );
        // console.log("//", response.data);
        props.setIsLoading(false);
        setReloadDomains(!reloadDomains);
        enqueueSnackbar("Domain deleted successfully!", {
          variant: "info",
          className: "bg-grey-400",
        });
      } catch (err) {
        props.setIsLoading(false);
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
          className: "bg-grey-400",
        });
      }
    }
    deleteCustomDomain(props.siteId, domainName);
  };

  function isValidSubdomain(subdomain) {
    // Regular expression to validate subdomain
    const subdomainRegex = /^[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?$/;

    // Check if the subdomain matches the regex
    return subdomainRegex.test(subdomain);
  }

  return (
    <div className="flex flex-col text-black h-screen  left-sidebar-domain">
      {isOpen && (
        <CustomDomainModal
          siteId={props.siteId}
          showStepper={isPointDNS && isStepper}
          isOpen={isOpen}
          domainName={currentDomainName}
          onClose={() => setIsOpen(false)}
          isPointDNS={isPointDNS}
        />
      )}
      <div className="bg-white-smoke z-0">
        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="full width tabs example"
            scrollButtons="auto"
            className="domain-tabs"
          >
            <Tab value={"subdomain"} label="Rocket Pages Domain"></Tab>
            <Tab value={"customdomain"} label="Custom Domain"></Tab>
          </Tabs>

          <TabPanel value={"subdomain"} className="px-0 bg-white-smoke z-0">
            <div className="flex flex-col">
              <span className="text-base-2 mb-2">Subdomain</span>
              <span className="font-normal mb-4">
                After publishing, this domain name will serve as your website's
                address, making it easily accessible to your customers or
                viewers.
              </span>
              <div className="flex flex-col lg:flex-row font-normal lg:items-baseline">
                <span className="mr-3">https://</span>
                <span className="flex flex-col">
                  <input
                    // disabled
                    className="border rounded border-catelina-blue/60 w-full sm:w-80 py-1 px-2"
                    placeholder="xyzwebsite"
                    value={props.subDomain}
                    onChange={(e) => {
                      let tmp_ErrorMessage = "";
                      if (e.target.value.toLowerCase() === "") {
                        tmp_ErrorMessage = "Subdomain can't be empty!";
                      } else if (
                        (e.target.value.toLowerCase() === "app" ||
                          e.target.value.toLowerCase() === "server" ||
                          e.target.value.toLowerCase() === "editor" ||
                          e.target.value.toLowerCase() === "apps" ||
                          e.target.value.toLowerCase() === "rocketpages" ||
                          e.target.value.toLowerCase() === "admin" ||
                          e.target.value.toLowerCase() === "support" ||
                          e.target.value.toLowerCase() === "billing" ||
                          e.target.value.toLowerCase() === "blog" ||
                          e.target.value.toLowerCase() === "docs" ||
                          e.target.value.toLowerCase() === "dashboard" ||
                          e.target.value.toLowerCase() === "marketplace" ||
                          e.target.value.toLowerCase() === "marketing" ||
                          e.target.value.toLowerCase() === "account" ||
                          e.target.value.toLowerCase() === "feedback" ||
                          e.target.value.toLowerCase() === "login" ||
                          e.target.value.toLowerCase() === "status" ||
                          e.target.value.toLowerCase() === "preview") &&
                        props.userData.userType !== "admin"
                      ) {
                        tmp_ErrorMessage = "Invalid Subdomain!";
                      } else {
                        if (isValidSubdomain(e.target.value.toLowerCase())) {
                          tmp_ErrorMessage = "";
                        } else {
                          tmp_ErrorMessage = "Invalid Subdomain!";
                        }
                      }

                      props.onDataChange({
                        subDomain: e.target.value.toLowerCase(),
                        subDomainErrorMessage: tmp_ErrorMessage,
                      });
                    }}
                  />
                  {props.subDomainErrorMessage !== "" && (
                    <span className={`text-sm mx-1 ${"text-red-500"}`}>
                      {props.subDomainErrorMessage}
                    </span>
                  )}
                </span>

                <span className="lg:ml-3">{process.env.SERVER_DOMAIN}</span>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={"customdomain"} className="px-0 bg-white-smoke z-0">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between max-w-lg">
                <span className="text-base-2 mb-2"></span>
                {/* <span className="font-normal mb-4">
              This will make your website accessible to your customers or
              viewers.
            </span> */}
                <Button
                  id="save"
                  className="w-40 py-1 z-0"
                  sx={{
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setIsOpen(true);
                    setIsPointDNS(false);
                    setIsStepper(false);
                  }}
                  disabled={domainWiseCount.length > 0}
                  size="small"
                  variant="contained"
                >
                  Add Custom Domain
                </Button>
              </div>

              <div className="flex flex-row justify-between font-normal items-center mt-4">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200 mb-0">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 w-72 tracking-wider">
                          Domains
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 w-36 tracking-wider">
                          Status
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 w-20 tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {domains.length > 0 &&
                        domains.map((rr) => {
                          // console.log("rr", rr);
                          let l_domainName = rr.domain;
                          return (
                            <tr key={rr.domain}>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                {rr.domain}

                                {rr.isPrimary ? (
                                  <span className="mx-2 px-2 inline-flex text-xsm leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                                    Primary
                                  </span>
                                ) : null}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                {rr.isValidated === "true" ? (
                                  <span className="px-2 inline-flex text-xsm leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Valid
                                  </span>
                                ) : (
                                  <span className="px-2 inline-flex text-xsm leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                    Invalid
                                  </span>
                                )}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap flex flex-row justify-end">
                                {rr.isValidated !== "true" && (
                                  <Button
                                    className="py-1 px-3 z-0 capitalize"
                                    onClick={() => handleValidate(rr.domain)}
                                    size="small"
                                    variant="contained"
                                  >
                                    Validate
                                  </Button>
                                )}

                                {/* <button
                                onClick={() => handleDelete(rr.domain)}
                                className="px-3 py-1 rounded-md bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring focus:border-red-300 ml-2"
                              >
                                Delete
                              </button> */}

                                <button
                                  className="w-10 mx-2"
                                  onClick={(e) => {
                                    handleClick(e, rr);
                                  }}
                                >
                                  <img
                                    // onClick={() => {
                                    //   props.onMoreOptionClick(rr.domain, rr.isValidated === "true", rr.isPrimary)
                                    // }}
                                    className="w-[36px] hover:cursor-pointer"
                                    // src={require("../../images/extra-option.svg")}
                                    src={require("../../images/extra-option.svg")}
                                    alt=""
                                  />
                                </button>

                                {/* Add more action buttons as needed */}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    {moreOptionMenu && (
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                        className="domain-menu-list"
                      >
                        <MenuItem
                          sx={textClassObj}
                          disabled={moreOptionMenu.isPrimary}
                          onClick={() => {
                            // console.log("ccc", moreOptionMenu.domain);
                            handleSetPrimaryDomain(moreOptionMenu.domain);
                          }}
                        >
                          Set as primary domain
                        </MenuItem>
                        <MenuItem
                          sx={textClassObj}
                          disabled={
                            moreOptionMenu.isValidated === "true" ? true : false
                          }
                          onClick={() => handleValidate(moreOptionMenu.domain)}
                        >
                          Validate domain
                        </MenuItem>
                        <MenuItem
                          sx={textClassObj}
                          onClick={() => {
                            setIsPointDNS(true);
                            setIsOpen(true);
                            setIsStepper(true);
                            setCurrentDomainName(moreOptionMenu.domain);
                          }}
                        >
                          Update DNS
                        </MenuItem>
                        <Divider sx={[{ marginTop: 2, marginBottom: 2 }]} />
                        <MenuItem
                          sx={[...textClassObj, { color: "#F00" }]}
                          onClick={() => handleDelete(moreOptionMenu.domain)}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default ConfigDomain;
