import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Navbar } from "../components/Layout/Navbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import Footer from "../components/Auth/Footer";
// import "../styles/editor.css";
import Button from "@mui/material/Button";
import ColorPalletsList from "../components/Admin/ColorPalletsList";
import TemplateCategoryList from "../components/Admin/TemplateCategoryList";
import PublishableTemplatesList from "../components/Admin/PublishableTemplatesList";
import UserAcquisitionLogs from "../components/Admin/UserAcquisitionLogs";

const AdminPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [cookies] = useCookies(["rp_user_data"]);
  const location = useLocation();
  const stateData = location.state;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let l_menu = query.get("menu");
  // console.log("l_menu", l_menu);
  return (
    <>
      <Helmet>
        <title>RocketPages - Admin Panel</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="font-body text-base font-medium">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Navbar
          {...stateData}
          title={`Admin Panel ${
            l_menu === "templateCategory"
              ? " | Template Category"
              : l_menu === "colorPallets"
              ? " | Color Pallets"
              : l_menu === "publishTemplates"
              ? " | Publish Templates"
              : l_menu === "userLogs"
              ? " | User acquisition logs"
              : ""
          }`}
        />
        <div className="font-body text-base font-medium bg-gray-100 w-full pt-[64px] flex flex-col min-h-screen-minus-100">
          {l_menu === "colorPallets" && <ColorPalletsList />}
          {l_menu === "templateCategory" && <TemplateCategoryList />}
          {l_menu === "publishTemplates" && <PublishableTemplatesList />}
          {l_menu === "userLogs" && <UserAcquisitionLogs />}
          {l_menu === null && (
            <div className="flex flex-row m-4">
              <Button
                id="save"
                className="min-w-[6rem] px-4 py-1 capitalize mr-2"
                sx={{
                  textTransform: "none",
                }}
                size="small"
                variant="contained"
                onClick={() => navigate(`/admin?menu=colorPallets`)}
              >
                Color Pallets
              </Button>

              <Button
                id="save"
                className="min-w-[6rem] px-4 py-1 capitalize mr-2"
                sx={{
                  textTransform: "none",
                }}
                size="small"
                variant="contained"
                onClick={() => navigate(`/admin?menu=templateCategory`)}
              >
                Template Category
              </Button>

              <Button
                id="save"
                className="min-w-[6rem] px-4 py-1 capitalize mr-2"
                sx={{
                  textTransform: "none",
                }}
                size="small"
                variant="contained"
                onClick={() => navigate(`/admin?menu=publishTemplates`)}
              >
                Publish Templates
              </Button>

              <Button
                id="save"
                className="min-w-[6rem] px-4 py-1 capitalize mr-2"
                sx={{
                  textTransform: "none",
                }}
                size="small"
                variant="contained"
                onClick={() => navigate(`/admin?menu=userLogs`)}
              >
                User acquisition logs
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </>
  );
};

export default AdminPage;