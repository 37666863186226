import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
// import "../../index.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ConfigPricingPlan = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies(["rp_user_data"]);
  const [isYearly, setIsYearly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planToBuy, setPlanToBuy] = useState<any>(null);

  const planFeatures = [
    {
      plan: "Forever Free",
      features: [
        "Attach custom domain",
        "Upto 1 GB storage",
        "Upto 10,000 visitors/month",
        "5 Contact form submissions/month",
        "Upto 1GB/month bandwidth",
        "Unlimited pages",
        "Unlimited team members",
        "Free SSL",
      ],
    },
    {
      plan: "Pro",
      features: [
        "Everything from Forever Free plan",
        "5 GB storage",
        "Upto 50,000 visitors/month",
        "50 Contact form submissions/month",
        "5 GB/month bandwidth",
        "No RocketPages branding",
      ],
    },
    {
      plan: "Business",
      features: [
        "Everything from Pro plan",
        "100 GB storage",
        "Upto 500,000 visitors/month",
        "Unlimited contact form submissions",
        "Unlimited bandwidth",
      ],
    },
  ];

  const togglePricing = () => {
    setIsYearly(!isYearly);
  };

  function checkPlanStatus(currentPlan) {
    if (
      props.activeSub?.selectedPlan &&
      currentPlan &&
      props.activeSub?.selectedPlan.price > currentPlan.price
    ) {
      return "Downgrade";
    } else {
      return "Upgrade";
    }
  }

  function upgradeSubscription(plan) {
    if (
      props.activeSub?.plan_id === plan.priceId &&
      props.activeSub?.cancel_at_period_end
    ) {
      resumeSubscription();
    } else if (props.activeSub?.plan_id) {
      setPlanToBuy(plan);
    } else {
      createStripeCheckout(plan.priceId);
    }
  }

  async function createStripeCheckout(priceId) {
    setIsLoading(true);
    let data = JSON.stringify({
      priceId: priceId,
      email: cookies.rp_user_data.email,
      siteId: props.id,
    });

    setPlanToBuy(null);
    let config = {
      method: "post",
      mode: "no-cors",
      maxBodyLength: Infinity,
      url: `${process.env.API_ENDPOINT}/api/configs/create-stripe-checkout-session`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      data: data,
    };

    let res = await axios.request(config);
    if (res.data.sessionUrl) {
      window.location.href = res.data.sessionUrl;
    } else {
      if (res.data.error) {
        setIsLoading(false);
        enqueueSnackbar(res.data.error, {
          variant: "error",
          className: "bg-catelina-blue",
        });
        return;
      } else if (res.data.status && res.data.msg) {
        setIsLoading(false);
        enqueueSnackbar(res.data.msg, {
          variant: "success",
          className: "bg-catelina-blue",
        });

        props.updateActiveSub(res.data.subscription);
        props.reFetch();
        return;
      }
    }
  }

  async function resumeSubscription() {
    setIsLoading(true);
    let data = JSON.stringify({
      subscriptionId: props.activeSub.subscriptionId,
      email: cookies.rp_user_data.email,
      siteId: props.id,
    });

    let config = {
      method: "put",
      mode: "no-cors",
      maxBodyLength: Infinity,
      url: `${process.env.API_ENDPOINT}/api/configs/site-config-resume-subscription`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      data: data,
    };

    let res = await axios.request(config);
    if (
      !res.data.error &&
      res.data.status &&
      !res.data.subscription.cancel_at_period_end
    ) {
      setIsLoading(false);
      enqueueSnackbar("Your subscription is resumed.", {
        variant: "success",
        className: "bg-catelina-blue",
      });
      props.updateActiveSub(res.data.subscription);
      props.reFetch();
    } else {
      setIsLoading(false);
      enqueueSnackbar(
        res.data.error ?? "Something went wrong while resuming subscription",
        {
          variant: "error",
          autoHideDuration: 9000,
        }
      );
    }
  }

  return (
    <section
      id="plans"
      className="config-pricing-plan flex flex-col justify-center items-center w-full"
    >
      {!!planToBuy && (
        <div className="fixed inset-0 overflow-y-auto flex justify-center items-center z-10">
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div className="bg-white max-w-xl rounded py-8 px-8 md:px-16 m-4 z-50 items-center flex text-center flex-col relative">
            <img
              src={require("../../images/close.svg")}
              className="absolute right-2 top-2 cursor-pointer"
              onClick={() => setPlanToBuy(null)}
            />
            <img
              src={require("../../images/update-icon.png")}
              className="mb-2"
            />
            <h4 className="mb-4">
              Update to {planToBuy?.plan} ({planToBuy?.duration}ly){" "}
            </h4>
            <p className="text-gray-800 mb-4">
              You are currently on the {props.activeSub?.selectedPlan?.plan} (
              {props.activeSub?.selectedPlan?.duration}ly) plan which expires on{" "}
              {new Date(
                props.activeSub?.subscription_end * 1000
              ).toLocaleDateString("en-GB")}
              .{" "}
            </p>

            <p className="text-grey-primary mb-4 font-normal">
              {props.activeSub?.selectedPlan?.price > planToBuy?.price && (
                <>
                  Your {planToBuy?.plan} ({planToBuy?.duration}ly) plan will
                  take effect at the end of your current billing cycle. You will
                  not be charged immediately. New plan will reflect in your
                  account post current plan's expiration.
                </>
              )}
              {props.activeSub?.selectedPlan?.price < planToBuy?.price && (
                <>
                  Your {planToBuy?.plan} ({planToBuy?.duration}ly) plan will
                  start and you will be charged immediately. Your current plan
                  will be replaced with the new plan.
                </>
              )}
            </p>
            <div className="flex justify-end flex-col sm:flex-row">
              <Button
                className="shadow-none"
                disabled={isLoading}
                // className="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                size="large"
                variant="contained"
                color="primary"
                onClick={() => createStripeCheckout(planToBuy.priceId)}
              >
                Update Plan
              </Button>
            </div>
          </div>
        </div>
      )}
      {/* <h2 className="my-4">Pricing Plans</h2> */}
      <div className="billing-cycle">
        <Box className="flex mb-4">
          <Box className="mask-box">
            <Box
              className="mask"
              style={{
                transform: `translateX(${isYearly === false ? 0 : "100px"})`,
              }}
            />
            <Button
              disableRipple
              variant="text"
              sx={{ color: isYearly === false ? "#ffffff" : "#243060" }}
              onClick={() => togglePricing()}
            >
              Monthly
            </Button>
            <Button
              disableRipple
              variant="text"
              sx={{ color: isYearly === true ? "#ffffff" : "#243060" }}
              onClick={() => togglePricing()}
            >
              Yearly
            </Button>
          </Box>
        </Box>
      </div>

      <div className="flex relative flex-col lg:flex-row grid grid-col-1 lg:grid-cols-3 md:grid-cols-1 gap-2 pricing-wrapper">
        {props.plans
          .filter((plan) => plan.duration === (isYearly ? "year" : "month"))
          .map((plan, index) => {
            return (
              <Card
                key={index}
                sx={{}}
                className="lg:m-0 mt-2 md:mt-0 shadow-none border border-gray p-2 rounded-lg  flex flex-col justify-between max-w-sm lg:max-w-auto w-full lg:w-auto m-auto"
              >
                <CardContent className="relative p-0">
                  <Box className="flex  border-b border-gray pb-3.5">
                    <h4
                      className="text-center m-0 pr-2"
                      style={{ color: "#243060" }}
                    >
                      {plan.plan}
                    </h4>

                    {!props.isLoading &&
                      ((props.activeSub?.plan_id === plan.priceId &&
                        props.activeSub?.subscription_status === "active") ||
                        (props.activeSub === undefined &&
                          plan.priceId == "")) && (
                        <p
                          className="border m-0 text-xs rounded-xl bg-catelina-blue text-white px-2"
                          style={{ fontSize: "12px" }}
                        >
                          Current Plan
                        </p>
                      )}
                  </Box>

                  <Box className="py-3.5 border-b border-gray">
                    <h4
                      className="m-0 flex items-center"
                      style={{ color: "#3B3B3B" }}
                    >
                      <sup>$</sup>
                      <span style={{ fontSize: "38px" }}>
                        {plan.price}
                      </span>{" "}
                      <span style={{ fontWeight: 300 }}>
                        /site/{plan.duration}
                      </span>
                    </h4>
                  </Box>
                  <ul className="list-disc list-none plan-feature p-0 mb-8 mt-3.5">
                    {planFeatures
                      .find((planFeature) => planFeature.plan === plan.plan)
                      ?.features.map((feature, idx) => (
                        <li key={idx} className="mb-1">
                          {feature}
                        </li>
                      ))}
                  </ul>
                </CardContent>
                {plan.price > 0 && (
                  <CardActions className="flex justify-center mb-4 p-0">
                    <Button
                      className="shadow-none w-full"
                      // disabled={buttonDisabled}
                      disabled={
                        props.isLoading ||
                        isLoading ||
                        (props.activeSub?.plan_id === plan.priceId &&
                          props.activeSub?.subscription_status !== "canceled" &&
                          (!props.activeSub?.cancel_at_period_end ||
                            (props.activeSub?.cancel_at_period_end &&
                              props.upcomingPhase))) ||
                        (props.upcomingPhase &&
                          props.upcomingPhase?.selectedPlan?.priceId ===
                            plan.priceId)
                      }
                      size="small"
                      variant="contained"
                      onClick={() => upgradeSubscription(plan)}
                    >
                      {props.isLoading && "Fetching current plan..."}

                      {!props.isLoading && (
                        <>
                          {props.activeSub?.plan_id === plan.priceId &&
                          props.activeSub?.cancel_at_period_end &&
                          !props.upcomingPhase
                            ? "Resume Subscription"
                            : checkPlanStatus(plan) + " to " + plan.plan}
                          {/* {props.activeSub?.plan_id === plan.priceId && !props.activeSub?.cancel_at_period_end && 'Current Subscription'}
                      {props.activeSub?.plan_id !== plan.priceId && (props.upcomingPhase && props.upcomingPhase?.selectedPlan?.priceId === plan.priceId) && 'Upcoming Subscription'}
                      {props.activeSub?.plan_id !== plan.priceId && (!props.upcomingPhase || props.upcomingPhase?.selectedPlan?.priceId !== plan.priceId) && 'Upgrade to ' + plan.plan} */}
                        </>
                      )}
                    </Button>
                  </CardActions>
                )}

                {plan.price > 0 && !props.isLoading && (
                  <>
                    {/* current subscription case */}
                    {props.activeSub?.plan_id === plan.priceId &&
                      !props.activeSub?.cancel_at_period_end && (
                        <div className="bg-[#E1E4EF] rounded p-2 mb-8">
                          <p className="text-sm mb-0.5 flex items-center text-catelina-blue">
                            <InfoOutlinedIcon
                              className="mr-1"
                              style={{ fontSize: 20 }}
                            />{" "}
                            {checkPlanStatus(plan)} button inactive:{" "}
                          </p>
                          <p className="text-sm mb-0 leading-4 font-normal">
                            You are currently on this plan.
                          </p>
                        </div>
                      )}
                    {props.activeSub?.plan_id === plan.priceId &&
                      props.activeSub?.cancel_at_period_end && (
                        <div className="bg-[#E1E4EF] rounded p-2 mb-8">
                          <p className="text-sm mb-0.5 flex items-center text-catelina-blue">
                            <InfoOutlinedIcon
                              className="mr-1"
                              style={{ fontSize: 20 }}
                            />{" "}
                            Attention
                          </p>
                          <p className="text-sm mb-0 leading-4 font-normal">
                            Your subscription ends at the current cycle. Click{" "}
                            <b>Resume Subscription</b> to continue your plan.
                          </p>
                        </div>
                      )}
                    {props.activeSub?.plan_id !== plan.priceId &&
                      props.upcomingPhase &&
                      props.upcomingPhase?.selectedPlan?.priceId ===
                        plan.priceId && (
                        <div className="bg-[#E1E4EF] rounded p-2 mb-8">
                          <p className="text-sm mb-0.5 flex items-center text-catelina-blue">
                            <InfoOutlinedIcon
                              className="mr-1"
                              style={{ fontSize: 20 }}
                            />{" "}
                            {checkPlanStatus(plan)} button inactive:{" "}
                          </p>
                          <p className="text-sm mb-0 leading-4 font-normal">
                            This is your upcoming plan which will auto-update
                            post current plan's expiration.
                          </p>
                        </div>
                      )}
                  </>
                )}
                {plan.price > 0 &&
                  !props.isLoading &&
                  props.activeSub?.plan_id &&
                  props.activeSub?.plan_id !== plan.priceId &&
                  (!props.upcomingPhase ||
                    props.upcomingPhase?.selectedPlan?.priceId !==
                      plan.priceId) && (
                    <>
                      {/* upgrade case */}
                      {props.activeSub?.amount_total / 100 < plan.price && (
                        <div className="bg-[#E1E4EF] rounded p-2 mb-8">
                          <p className="text-sm mb-0.5 flex items-center text-catelina-blue">
                            <InfoOutlinedIcon
                              className="mr-1"
                              style={{ fontSize: 20 }}
                            />{" "}
                            Attention{" "}
                          </p>
                          <p className="text-sm mb-0 leading-4 font-normal">
                            Upgrading to the {plan.plan} ({plan.duration}ly)
                            plan will process your payment immediately
                            (prorated), and access to new features will be
                            granted.
                          </p>
                        </div>
                      )}

                      {/* downgrade case */}
                      {props.activeSub?.amount_total / 100 > plan.price && (
                        <div className="bg-[#E1E4EF] rounded p-2 mb-8">
                          <p className="text-sm mb-0.5 flex items-center text-catelina-blue">
                            <InfoOutlinedIcon
                              className="mr-1"
                              style={{ fontSize: 20 }}
                            />{" "}
                            Attention{" "}
                          </p>
                          <p className="text-sm mb-0 leading-4 font-normal">
                            Downgrading to the {plan.plan} ({plan.duration}ly)
                            plan will prorate your payment from next billing
                            cycle. You can access your current features until
                            then.
                          </p>
                        </div>
                      )}
                    </>
                  )}
              </Card>
            );
          })}
      </div>
    </section>
  );
};

export default ConfigPricingPlan;
